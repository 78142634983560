<template>
  <div class='container user-test-report-box fl-s-c'>
    <!-- 导航栏 -->
    <van-nav-bar title="检测图" left-arrow @click-left="onClickNavLeftBtn" fixed safe-area-inset-top />
    <!-- 页面数据信息部分 -->
    <div class="w-1 flex-1 page-content-part-wrap-box fl-b-b" :class="{'page-images-ff':fmtIsShowImgWhiteBg}" v-if="isShowPageData">
      <div class="w-1 flex-1 fl-c relative skin-test-results-images-change-slider-dialog-part-wrap-box">
        <!-- 检测报告图片信息 -->
        <div class="w-1 flex-1 fl-c skin-test-results-images-dialog-data-btn-show-content-part-box">
          <div class="w-1 fl-c skin-test-results-images-movable-area-part-box" ref="areaBoxRef">
            <div class="fl-c skin-test-results-images-movable-div-part-box" ref="dragBoxRef" @wheel.prevent="handleMouseWheelScaleScroll" @mousedown="handleMouseDownMove" @touchmove.prevent="handleTouchMoveCallback" @touchstart.prevent="handleTouchStartCallback" @touchend.prevent="handleTouchEndCallback" :style="{transform:`scale(${scaleOffsetVal.scaleValue}) translate(${scaleOffsetVal.offsetValueX}px, ${scaleOffsetVal.offsetValueY}px)`}">
              <div class="relative skin-test-results-images-box" ref="imgRef">
                <!-- 底层图片 -->
                <img class="iamges-style images-bottom-layer" alt="底层图片" :src="skinImgCurrentObj[skinTestType==0||skinTestType==1?'skinBaseUrl':'skinBaseTestUrl']" />
                <!-- 叠加层样式部分 -->
                <div class="w-1 relative overlay-layer-style-part-box">
                  <!-- 底图 -->
                  <div class="overlay-layer-box base-map-images-box" :style="{width: `${imgSlideX}%`}" v-if="fmtIsShowChangeBswl">
                    <img class="iamges-style images-base-map" alt="底图" :src="skinImgCurrentObj[skinTestType==1?'skinBaseUrl':'skinBaseTestUrl']" />
                  </div>
                  <!-- 叠加图片 -->
                  <div class="w-1 h-1 overlay-layer-box stack-iamges-box" v-if="skinTestBigType!='原图'&&skinTestType!=0">
                    <img class="iamges-style iamges-stack" alt="叠加图片" :src="skinImgCurrentObj[skinTestType==1?'skinDestUrl':'skinDestTestUrl']" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 展示滑动条、原图/症状/预测切换部分 -->
        <div class="w-1 skin-test-results-type-change-slider-part-wrap-box fr-c-e">
          <div class="w-1 h-1 skin-test-results-type-change-slider-content-part-box fl-c" v-if="fmtIsShowChangeSlider">
            <div class="w-1 fr-c skin-test-results-type-change-part-box">
              <div class="results-type-change-box fr-b-c">
                <div class="change-type-item fr-c" :class="{'active':skinTestType==0}" v-if="fmtIsShowChangeOther" @click.stop="clickResultChangeTypeItemBtn(0)">原图</div>
                <div class="change-type-item fr-c" :class="{'active':skinTestType==2}" v-if="fmtIsShowChangeBswl" @click.stop="clickResultChangeTypeItemBtn(2)">预测</div>
                <div class="change-type-item fr-c" :class="{'active':skinTestType==1}" @click.stop="clickResultChangeTypeItemBtn(1)">症状</div>
              </div>
            </div>
            <!-- skinTestType!=0&& -->
            <div class="w-1 skin-test-results-slider-part-box fr-c" v-if="fmtIsShowChangeBswl">
              <van-slider v-model="imgSlideX" :min="0" :max="100" inactive-color="rgba(255, 255, 255, 0.4)" active-color="#ffffff"></van-slider>
            </div>
          </div>
        </div>

      </div>

      <!-- 页面底部-检测大类切换 -->
      <div class="w-1 page-bootom-skin-test-result-imgTab-part-wrap-box fr-c">
        <div class="h-1 bootom-skin-test-result-imgTab-scroll">
          <ul class="bootom-skin-test-result-imgTab-content-part-box fr-s-c ">
            <li class="skin-test-imgTab-item-box fl-c" :class="{'active':skinTestBigType==item.skinText}" v-for="(item,index) in skinImgTabList" :key="index">
              <div class="imgTab-item-content-box fl-c" @click.stop="clickSkinTestImgTabBtnItem(item)">
                <div class="imgTab-item-small-img-box fr-c relative">
                  <img class="iamges-small-base" alt="底图" :src="item.skinBaseUrl" />
                  <img class="iamges-small-dest" alt="叠加图" :src="item.skinDestUrl" v-if="item.skinText!='原图'" />
                </div>
                <p class="imgTab-item-text">{{item.skinText}}</p>
              </div>
            </li>
          </ul>
        </div>

      </div>

    </div>

    <!-- 右上角操作按钮部分  -->
    <div class="page-right-top-fixed-btn-show-part-wrap-box" :class="{'right-top-fixed-ff':fmtIsShowImgWhiteBg}" v-if="isShowPageData">
      <ul class="right-top-fixed-btn-show-content-part-box fl-s-c">
        <!-- 左脸 -->
        <li class="right-top-btn-item-box fl-c" :class="{'active':faceDirection==-1,'disable':!fmtIsDirection(-1)}" @click.stop="clickRightTopBtnItem(1,-1)">
          <div class="btn-item-iocn-box fr-c">
            <img class="images-btn-icon" alt="左脸" :src="faceDirection==-1?left_face_active:left_face_default" />
          </div>
          <p class="btn-item-text">左脸</p>
        </li>
        <!-- 正脸 -->
        <li class="right-top-btn-item-box fl-c" :class="{'active':faceDirection==0,'disable':!fmtIsDirection(0)}" @click.stop="clickRightTopBtnItem(1,0)">
          <div class="btn-item-iocn-box fr-c">
            <img class="images-btn-icon" alt="正脸" :src="faceDirection==0?main_face_active:main_face_default" />
          </div>
          <p class="btn-item-text">正脸</p>
        </li>
        <!-- 右脸 -->
        <li class="right-top-btn-item-box fl-c" :class="{'active':faceDirection==1,'disable':!fmtIsDirection(1)}" @click.stop="clickRightTopBtnItem(1,1)">
          <div class="btn-item-iocn-box fr-c">
            <img class="images-btn-icon" alt="右脸" :src="faceDirection==1?right_face_active:right_face_default" />
          </div>
          <p class="btn-item-text">右脸</p>
        </li>
        <!-- 数据 -->
        <li class="right-top-btn-item-box fl-c" :class="{'active':isShowDataPop}" @click.stop="clickRightTopBtnItem(2)">
          <div class="btn-item-iocn-box fr-c">
            <img class="images-btn-icon" alt="数据" :src="isShowDataPop?right_face_active:right_face_default" />
          </div>
          <p class="btn-item-text">数据</p>
        </li>
        <!-- 对比 -->
        <li class="right-top-btn-item-box fl-c" @click.stop="clickRightTopBtnItem(3)">
          <div class="btn-item-iocn-box fr-c">
            <img class="images-btn-icon" alt="对比" :src="contrast_icon" />
          </div>
          <p class="btn-item-text">对比</p>
        </li>
      </ul>
    </div>

    <!-- 左上角数据部分 -->
    <div class="page-left-top-fixed-data-show-part-wrap-box" v-if="isShowPageData&&isShowDataPop">
      <div class="w-1 left-top-fixed-data-show-content-part-box">
        <!-- 原图 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='原图'">
          <div class="left-top-data-item-box fr-s-c">卟啉数量:{{faceCurrentData.acneNum||0}}</div>
          <div class="left-top-data-item-box fr-s-c">卟啉程度:{{faceCurrentData.acneScore||0}}</div>
          <div class="left-top-data-item-box fr-s-c">卟啉占比:{{faceCurrentData.acneRatio||0}}</div>
          <div class="left-top-data-item-box fr-s-c">红区数量:{{faceCurrentData.redspotNum||0}}</div>
          <div class="left-top-data-item-box fr-s-c">红区程度:{{faceCurrentData.redspotScore||0}}</div>
          <div class="left-top-data-item-box fr-s-c">红区占比:{{faceCurrentData.redspotRatio||0}}</div>
        </div>
        <!-- 表素 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='表素'">
          <div class="left-top-data-item-box fr-s-c">表素数量:{{faceCurrentData.surfacespotNum||0}}</div>
          <div class="left-top-data-item-box fr-s-c">表素得分:{{faceCurrentData.surfacespotScore||0}}</div>
          <div class="left-top-data-item-box fr-s-c">表素占比:{{faceCurrentData.surfacespotRatio||0}}</div>
          <div class="left-top-data-item-box fr-s-c">表素面积:{{faceCurrentData.surfacespotArea||0}}</div>
        </div>
        <!-- 棕斑 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='棕斑'">
          <div class="left-top-data-item-box fr-s-c">棕色斑数量:{{faceCurrentData.brownspotNum||0}}</div>
          <div class="left-top-data-item-box fr-s-c">棕色斑得分:{{faceCurrentData.brownspotScore||0}}</div>
          <div class="left-top-data-item-box fr-s-c">棕色斑占比:{{faceCurrentData.brownspotRatio||0}}</div>
          <div class="left-top-data-item-box fr-s-c">棕色斑面积:{{faceCurrentData.brownspotArea||0}}</div>
        </div>
        <!-- 深斑 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='深斑'">
          <div class="left-top-data-item-box fr-s-c">深斑数量:{{faceCurrentData.uvdeepspotNum||40}}</div>
          <div class="left-top-data-item-box fr-s-c">深斑得分:{{faceCurrentData.uvdeepspotScore||90}}</div>
          <div class="left-top-data-item-box fr-s-c">深斑占比:{{faceCurrentData.uvdeepspotRatio||0.5}}</div>
          <div class="left-top-data-item-box fr-s-c">深斑面积:{{faceCurrentData.uvdeepspotArea||0.5}}</div>
        </div>
        <!-- 红区 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='红区'">
          <div class="left-top-data-item-box fr-s-c">红区数量:{{faceCurrentData.redspotNum||20}}</div>
          <div class="left-top-data-item-box fr-s-c">红区得分:{{faceCurrentData.redspotScore||30}}</div>
          <div class="left-top-data-item-box fr-s-c">红区占比:{{faceCurrentData.redspotRatio||0.5}}</div>
          <div class="left-top-data-item-box fr-s-c">红区面积:{{faceCurrentData.redspotArea||0.5}}</div>
        </div>
        <!-- 毛孔 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='毛孔'">
          <div class="left-top-data-item-box fr-s-c">毛孔得分:{{faceCurrentData.poreNum||30}}</div>
          <div class="left-top-data-item-box fr-s-c">毛孔数量:{{faceCurrentData.poreScore||20}}</div>
          <div class="left-top-data-item-box fr-s-c">毛孔占比:{{faceCurrentData.poreRatio||0.5}}</div>
          <div class="left-top-data-item-box fr-s-c">毛孔面积:{{faceCurrentData.poreArea||0.5}}</div>
        </div>
        <!-- 粉刺 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='粉刺'">
          <div class="left-top-data-item-box fr-s-c">卟啉数量:{{faceCurrentData.acneNum||20}}</div>
          <div class="left-top-data-item-box fr-s-c">卟啉得分:{{faceCurrentData.acneScore||30}}</div>
          <div class="left-top-data-item-box fr-s-c">卟啉占比:{{faceCurrentData.acneRatio||0.5}}</div>
          <div class="left-top-data-item-box fr-s-c">卟啉面积:{{faceCurrentData.acneArea||0.5}}</div>
        </div>
        <!-- 纹理 -->
        <div class="w-1 left-top-data-content-box fr-b-s warp" v-if="skinTestBigType=='纹理'">
          <div class="left-top-data-item-box fr-s-c">纹理数量:{{faceCurrentData.textureNum||20}}</div>
          <div class="left-top-data-item-box fr-s-c">纹理得分:{{faceCurrentData.textureScore||30}}</div>
          <div class="left-top-data-item-box fr-s-c">纹理占比:{{faceCurrentData.textureRatio||0.5}}</div>
          <div class="left-top-data-item-box fr-s-c">纹理面积:{{faceCurrentData.textureArea||0.5}}</div>
        </div>

      </div>
      <div class="w-1 left-top-fixed-data-bottom-arrow-part-box fr-c">
        <img class="image-fixed-data-arrow" :src="down_arrow_icon" />
      </div>
    </div>

    <!-- 数据对比选择时间弹窗 -->
    <changeTimedDialog :is_popup.sync="isPopup" @changDatePopConfirmInfo="clickChangDatePopConfirmBtn"></changeTimedDialog>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import reportManager from '@/request/reportManager.js'
import { skinTestResults, skinTestImgTab } from './components/skin_test_results';   //皮肤检测结果
import changeTimedDialog from './components/change-time-dialog.vue';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { changeTimedDialog },
  data () {
    // 这里存放数据
    return {
      data_default: require('@/assets/image/testReport/report_black_data_default_icon.png'), //数据-黑底-图标(黑底)
      data_active: require('@/assets/image/testReport/report_black_data_active_icon.png'), //数据-选中-图标(黑底)
      left_face_default: require('@/assets/image/testReport/report_black_face_left_default_icon.png'), //左脸-默认-图标(黑底)
      left_face_active: require('@/assets/image/testReport/report_black_face_left_active_icon.png'), //左脸-选中-图标(黑底)
      main_face_default: require('@/assets/image/testReport/report_black_face_main_default_icon.png'), //正脸-默认-图标(黑底)
      main_face_active: require('@/assets/image/testReport/report_black_face_main_active_icon.png'), //正脸-选中-图标(黑底)
      right_face_default: require('@/assets/image/testReport/report_black_face_right_default_icon.png'), //右脸-默认-图标(黑底)
      right_face_active: require('@/assets/image/testReport/report_black_face_right_active_icon.png'), //右脸-选中-图标(黑底)
      contrast_icon: require('@/assets/image/testReport/report_black_contrast_icon.png'), //对比-图标(黑底)
      slider_btn: require('@/assets/image/testReport/report_slider_btn_icon.png'), //滑块按钮-图标
      down_arrow_icon: require('@/assets/image/testReport/report_resultData_down_arrow_icon.png'), //数据结果-图标

      isShowPageData: false,   //是否显示页面数据
      scanningResultRecordId: 0,  //测肤结果id
      isShowDataPop: true,  //是否显示-检测数据信息
      isPopup: false, //弹窗，为false时隐藏，为true时显示

      resultsListData: [],  //结果数据   skinTestResults().diagnosisSkinList
      faceDirectionStr: [], //脸部方向数据
      faceDirection: 0,  //面部方向(-1左,0中,1右)
      faceCurrentData: {},   //面部当前方向数据  skinTestResults().diagnosisSkinList.find(item => item.direction == 0)
      skinTestBigType: '原图',   //皮肤检测大类 (原图、表素、棕斑-白、深斑、红区-白、毛孔、粉刺、纹理)
      skinImgTabList: [],   //底部展示-全部类型切换
      skinImgCurrentObj: {},  //当前展示检测图片数据 

      imgSlideX: 50,   //图片-宽度（取X轴值）
      skinTestType: 0,   //原图/症状/预测切换类型 0:原图、1:症状、2:预测

      scaleOffsetVal: {
        moveStart: { x: 0, y: 0 }, //拖拽元素初始位置
        scaleValue: 1,   //图片-缩放比例值
        offsetValueX: 0,  //移动X轴位置
        offsetValueY: 0,//移动Y轴位置
        distanceValue: 0,  //两指之间距离
        isZoom: false,   //是否缩放
      },

    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.scanningResultRecordId = this.$route.query.scanningResultRecordId || 139;
    this.getPageSkinTestResultData();  //获取页面检测结果数据
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {

    // 获取页面检测结果数据
    async getPageSkinTestResultData () {
      this.isShowLoading = false
      try {
        let { code, data, message } = await reportManager.getSkinTestReport({ scanningResultRecordId: this.scanningResultRecordId })  //测肤结果id
        if (code == 200 && data) {
          let report_arr = JSON.parse(data)
          if (report_arr.length) {
            this.faceDirectionStr = report_arr.map(item => item.direction) //报告中脸部方向数据
            this.getCurrentDirectionValData(); //处理当前默认脸部方向值
            let face_current_obj = report_arr.find(item => item.direction == this.faceDirection)  //面部当前方向数据
            this.resultsListData = report_arr     //结果数据
            this.faceCurrentData = face_current_obj  //面部当前方向数据
            this.skinImgTabList = skinTestImgTab(face_current_obj)   //底部展示-全部类型切换 
            this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
          }
          this.isShowPageData = report_arr.length > 0
        }
      } catch (error) {
        console.error('获取页面检测结果数据-error', error)
      } finally {
        this.isShowLoading = true
      }
    },
    // 处理当前默认脸部方向值
    getCurrentDirectionValData () {
      if (this.faceDirectionStr.length) this.faceDirection = this.fmtIsDirection(0) ? 0 : this.faceDirectionStr[0];
    },


    // 点击切换 原图、症状、预测 
    clickResultChangeTypeItemBtn (change_type) {
      this.skinTestType = change_type;   //原图/症状/预测切换类型 0:原图、1:症状、2:预测
    },
    // 点击切换检测报告-大类展示
    clickSkinTestImgTabBtnItem (item) {
      this.skinTestBigType = item.skinText  //皮肤检测大类 (原图、表素、棕斑-白、深斑、红区-白、毛孔、粉刺、纹理)
      this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
    },
    // 处理右上角按钮的点击事件，根据按钮类型执行相应的操作
    clickRightTopBtnItem (action_type, action_value) {
      switch (action_type) {
        case 1: // 面部方向
          this.getHandleFaceDirectionData(action_value);   //处理面部方向改变
          break;
        case 2: // 数据
          this.getHandleDataActionData();  //处理数据相关操作
          break;
        case 3: // 对比
          this.getHandleComparisonData();  //处理对比操作
          break;
        default:
          console.warn(`Unhandled action type: ${action_type}`);
          break;
      }
    },
    // 处理面部方向改变
    getHandleFaceDirectionData (direction_val) {
      if (!this.fmtIsDirection(direction_val)) return;
      const resultItem = this.resultsListData.find(item => item.direction === direction_val);
      if (resultItem !== undefined) {
        this.faceDirection = direction_val;
        this.faceCurrentData = resultItem;
        this.skinImgTabList = skinTestImgTab(resultItem);
        this.getBallMoveInitXData();
      } else {
        console.warn(`No result found for direction ${direction_val}`);
      }
    },

    // 处理数据相关操作
    getHandleDataActionData () {
      this.isShowDataPop = !this.isShowDataPop;
    },

    // 处理对比操作
    getHandleComparisonData () {
      this.isPopup = true;
    },
    // 选择对比时间弹窗--确认按钮
    clickChangDatePopConfirmBtn (chang_date_obj) {
      this.$router.push({
        path: '/report/contrastReport',
        query: {
          startScanningResultRecordId: chang_date_obj.startResultRecordId, //开始-测肤结果id
          startResultReportTime: chang_date_obj.startReportTime,//开始报告时间
          endScanningResultRecordId: chang_date_obj.endResultRecordId,//结束-测肤结果id
          endResultReportTime: chang_date_obj.endReportTime,//结束报告时间
        }
      })
    },


    // 滑动对比--X轴初始值（默认为展示X轴一半距离）
    getBallMoveInitXData () {
      let { skinImgTabList, skinTestBigType } = this   //从data中取数据
      this.skinImgCurrentObj = skinImgTabList.find(item => skinTestBigType == item.skinText)  //当前展示检测图片数据
      this.imgSlideX = 50   //图片-宽度（取X轴值）
      this.skinTestType = this.getHandleCurrentType();   //原图/症状/预测切换类型 0:原图、1:症状、2:预测
    },
    // 处理当前展示切换类型
    getHandleCurrentType () {
      return this.fmtIsShowChangeBswl ? 2 : 0;
    },


    // (移动端)手触摸-开始
    handleTouchStartCallback (e) {
      // console.log('(移动端)手触摸-开始', e)
      if (e.touches.length === 1) {  //单指移动
        let { clientX, clientY } = e.touches[0];
        this.scaleOffsetVal.moveStart.x = clientX
        this.scaleOffsetVal.moveStart.y = clientY
      } else {  //双指缩放
        let xMove = e.touches[1].clientX - e.touches[0].clientX;
        let yMove = e.touches[1].clientY - e.touches[0].clientY;
        let distance = Math.sqrt(xMove * xMove + yMove * yMove);
        this.scaleOffsetVal.distanceValue = distance
        this.scaleOffsetVal.isZoom = true
      }
    },
    // (移动端)手触摸-进行中
    handleTouchMoveCallback (e) {
      // console.log('(移动端)手触摸-进行中', e)
      let { isZoom, scaleValue, offsetValueX, offsetValueY, distanceValue, moveStart } = this.scaleOffsetVal
      if (e.touches.length === 1) {  //单指移动
        if (isZoom || scaleValue == 1) return; //缩放状态，不处理单指
        let { clientX, clientY } = e.touches[0];
        let offsetX = clientX - moveStart.x;
        let offsetY = clientY - moveStart.y;

        this.scaleOffsetVal.offsetValueX = offsetValueX + offsetX;
        this.scaleOffsetVal.offsetValueY = offsetValueY + offsetY;
        this.scaleOffsetVal.moveStart.x = clientX;
        this.scaleOffsetVal.moveStart.y = clientY;
        // console.log(`移动结果 x:${this.scaleOffsetVal.offsetValueX},y:${this.scaleOffsetVal.offsetValueY}`)

      } else {  //双指缩放
        let xMove = e.touches[1].clientX - e.touches[0].clientX;
        let yMove = e.touches[1].clientY - e.touches[0].clientY;
        let distance = Math.sqrt(xMove * xMove + yMove * yMove);
        let newScale = scaleValue + 0.005 * (distance - distanceValue);
        let scale_value = newScale > 1 ? newScale : 1;

        this.scaleOffsetVal.distanceValue = distance;
        this.scaleOffsetVal.scaleValue = scale_value;
        this.scaleOffsetVal.offsetValueX = scale_value > 1 ? offsetValueX : 0;
        this.scaleOffsetVal.offsetValueY = scale_value > 1 ? offsetValueY : 0;
        // console.log('scaleValue', this.scaleOffsetVal.scaleValue)
      }
    },
    // (移动端)手触摸-结束
    handleTouchEndCallback (e) {
      // console.log('(移动端)手触摸-结束', e)
      if (e.touches.length === 0) this.scaleOffsetVal.isZoom = false;
    },

    // (PC端)鼠标滚轮事件-缩放
    handleMouseWheelScaleScroll (e) {
      let { scaleValue, offsetValueX, offsetValueY } = this.scaleOffsetVal
      const delta_val = Math.sign(-e.deltaY) * 0.1;
      let scale_val = scaleValue + delta_val
      if (scale_val >= 1 && scale_val <= 4) this.scaleOffsetVal.scaleValue = scale_val;
      this.scaleOffsetVal.offsetValueX = scale_val > 1 ? offsetValueX : 0;
      this.scaleOffsetVal.offsetValueY = scale_val > 1 ? offsetValueY : 0;
      // console.log('(PC端)鼠标滚轮事件-缩放', this.scaleOffsetVal.scaleValue)
    },
    // (PC端)鼠标拖拽事件-移动
    handleMouseDownMove (e) {
      // console.log('(PC端)鼠标拖拽事件-移动',e)
      if (e.button !== 0) return;
      if (this.scaleOffsetVal.scaleValue == 1) return;
      this.scaleOffsetVal.moveStart.x = e.clientX
      this.scaleOffsetVal.moveStart.y = e.clientY
      // 鼠标拖动事件
      document.onmousemove = (evt) => {
        // console.log('(PC端)鼠标拖动事件', evt)
        let { scaleValue, moveStart } = this.scaleOffsetVal
        this.scaleOffsetVal.offsetValueX += (evt.clientX - moveStart.x) / scaleValue
        this.scaleOffsetVal.offsetValueY += (evt.clientY - moveStart.y) / scaleValue
        this.scaleOffsetVal.moveStart.x = evt.clientX
        this.scaleOffsetVal.moveStart.y = evt.clientY
        // console.log('结果-(PC端)鼠标拖拽事件-移动', this.scaleOffsetVal.offsetValueX, this.scaleOffsetVal.offsetValueY)
      };
      document.onmouseup = () => { document.onmousemove = null; };
    },


    // 点击导航栏返回按钮
    onClickNavLeftBtn () {
      // console.log('点击导航栏返回按钮')
      this.$router.go(-1);
    },

  },
  // 监听属性 类似于data概念
  computed: {
    fmtIsShowImgWhiteBg () {   //是否页面显示白色背景
      return ['棕斑', '红区'].indexOf(this.skinTestBigType) !== -1; //&& this.skinTestType != 0
    },
    fmtIsShowChangeSlider () {   //是否显示切换、滑块正式部分
      return this.skinTestBigType != '原图';
    },
    fmtIsShowChangeBswl () {   //是否显示症状、预测 切换
      return ['表素', '纹理'].indexOf(this.skinTestBigType) !== -1;
    },
    fmtIsShowChangeOther () {   //是否显示原图、症状切换
      return ['棕斑', '深斑', '红区', '毛孔', '粉刺'].indexOf(this.skinTestBigType) !== -1;
    },
    /**
     * 检查指定的方向值是否存在于faceDirectionStr数组中。
     * @param {string} direction_val - 要检查的方向值。
     * @returns {boolean} 如果方向值存在于数组中，则返回true；否则返回false。
     */
    fmtIsDirection () {
      return (direction_val) => {
        if (!Array.isArray(this.faceDirectionStr)) return false;
        try { return this.faceDirectionStr.includes(direction_val); } catch (error) { return false; }
      }
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.user-test-report-box {
  --nav-height: calc(90px + env(safe-area-inset-top));
  --img-width: 750px;
  --img-height: 931px;
}
.container {
  min-height: 100vh;
  padding-top: var(--nav-height);
  box-sizing: border-box;
  background: #000000;
}
/* 页面数据信息部分--默认背景色 */
.page-content-part-wrap-box {
  .skin-test-results-images-change-slider-dialog-part-wrap-box {
    // 检测报告图片信息
    .skin-test-results-images-dialog-data-btn-show-content-part-box {
      .skin-test-results-images-movable-area-part-box {
        width: var(--img-width);
        height: var(--img-height);
        overflow: hidden;
        position: relative;
        cursor: move;
        .skin-test-results-images-movable-div-part-box {
          width: var(--img-width);
          height: var(--img-height);
          position: absolute;
        }
        .skin-test-results-images-box,
        .overlay-layer-style-part-box {
          width: var(--img-width);
          height: var(--img-height);
        }
        .iamges-style {
          width: var(--img-width);
          height: var(--img-height);
        }

        /* 底层图片 */
        .images-bottom-layer {
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
        }
        /* 叠加层样式部分 */
        .overlay-layer-box {
          overflow: hidden;
          position: absolute;
          top: 0px;
          left: 0px;
        }

        .base-map-images-box {
          z-index: 2;
        }
      }
    }

    // 展示滑动条、原图/症状/预测切换部分
    .skin-test-results-type-change-slider-part-wrap-box {
      height: 200px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.06) 15%,
        rgba(0, 0, 0, 0.6) 100%
      );
      padding: 60px 20px 10px;
      box-sizing: border-box;
      position: absolute;
      left: 0px;
      bottom: 0px;
      z-index: 3;
      .skin-test-results-type-change-part-box {
        margin-bottom: 20px;
        .results-type-change-box {
          width: 224px;
          height: 60px;
          border-radius: 16px;
          border: 2px solid #ffffff;
          overflow: hidden;

          .change-type-item {
            width: 112px;
            height: 100%;
            font-size: 26px;
            color: #fafafa;
            &.active {
              background: #ffffff;
              color: #323232;
            }
          }
        }
      }

      .skin-test-results-slider-part-box {
        height: 55px;
      }
    }
  }

  // 页面底部-检测大类切换
  .page-bootom-skin-test-result-imgTab-part-wrap-box {
    height: 212px;
    padding: 24px 0px 38px;
    box-sizing: border-box;
    .bootom-skin-test-result-imgTab-scroll {
      overflow-x: auto;
      padding: 10px 30px;
      box-sizing: border-box;
      white-space: nowrap;

      .bootom-skin-test-result-imgTab-content-part-box {
        display: inline-flex;
        .skin-test-imgTab-item-box {
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 30px;
          }

          .imgTab-item-small-img-box {
            width: 76px;
            height: 88px;
            border-radius: 16px;
            border: 1px solid rgba(255, 255, 255, 0.4);
            overflow: hidden;
            margin-bottom: 8px;

            .iamges-small-base {
              width: 76px;
              height: 88px;
            }
            .iamges-small-dest {
              width: 76px;
              height: 88px;
              position: absolute;
              top: 0px;
              left: 0px;
            }
          }
          .imgTab-item-text {
            font-size: 22px;
            color: rgba(255, 255, 255, 0.6);
          }

          &:hover {
            .imgTab-item-text {
              color: rgba(255, 255, 255, 1);
            }
          }
          &.active {
            .imgTab-item-small-img-box {
              border-color: #ffffff;
            }
            .imgTab-item-text {
              color: rgba(255, 255, 255, 1);
              font-weight: bold;
            }
          }
          &.disable {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }
  }

  &.page-images-ff {
    .skin-test-results-images-change-slider-dialog-part-wrap-box {
      background: #ffffff;

      // 展示滑动条、原图/症状/预测切换部分
      .skin-test-results-type-change-slider-part-wrap-box {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.06) 15%,
          rgba(0, 0, 0, 0.6) 100%
        );
      }
    }
  }
}

/* 右上角操作按钮部分 */
.page-right-top-fixed-btn-show-part-wrap-box {
  width: 75px;
  padding-top: var(--nav-height);
  box-sizing: border-box;
  position: fixed;
  top: 35px;
  right: 24px;
  z-index: 9;
  .right-top-btn-item-box {
    width: 100%;
    cursor: pointer;
    .btn-item-iocn-box {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 8px;
      .images-btn-icon {
        width: 75px;
        height: 75px;
      }
    }
    .btn-item-text {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.7);
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    &:not(.active) {
      .btn-item-iocn-box {
        background: rgba(0, 0, 0, 0.6);
      }
    }
    &.active {
      .btn-item-text {
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
      }
    }
  }
  &.right-top-fixed-ff {
    .right-top-btn-item-box {
      .btn-item-text {
        color: rgba(50, 50, 50, 1);
      }
      &.active {
        .btn-item-text {
          color: rgba(74, 144, 226, 1);
          font-weight: bold;
        }
      }
    }
  }
}

/* 左上角数据部分 */
.page-left-top-fixed-data-show-part-wrap-box {
  width: 520px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 24px;
  position: fixed;
  top: calc(var(--nav-height) + 30px);
  left: 24px;
  z-index: 9;
  padding: 16px 0px;
  box-sizing: border-box;
  .left-top-fixed-data-show-content-part-box {
    padding: 0px 10px 0px 22px;
    box-sizing: border-box;
    .left-top-data-item-box {
      width: 49%;
      margin-bottom: 16px;
      font-size: 24px;
      color: #fafafa;
    }
  }
  .left-top-fixed-data-bottom-arrow-part-box {
    padding: 5px 52px 0px 24px;
    box-sizing: border-box;
    .image-fixed-data-arrow {
      width: 27px;
      height: 16px;
    }
  }
}

/* 导航栏 */
.van-nav-bar--fixed {
  z-index: 99;
}
</style>
