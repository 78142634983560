<template>
  <div class='container'>
    <van-nav-bar title="测肤报告" left-arrow fixed placeholder @click-left="close" />
    <div class="con">
      <el-descriptions title="" :column="3" border>
        <el-descriptions-item label="肤龄:">{{dataInfo.age}}</el-descriptions-item>
        <el-descriptions-item label="肤色:">{{dataInfo.color|getStateName(colorType)}}</el-descriptions-item>
        <el-descriptions-item label="肤质:">{{dataInfo.skinType|getStateName(skinType)}}</el-descriptions-item>
      </el-descriptions>
    </div>

    <div class="con" v-for="(item,index) in dataList" :key="index">
      <div class="fr-b-c">
        <div class="tit">{{item.name}}</div>
        <div>
          <el-tag class="tag" effect="dark">{{item.value}}分</el-tag>
        </div>
      </div>
      <div class="fr-b-c">
        <img class="imgs" :src="item.img" alt="">
        <div class="fr-c titles">
          <div class="info" v-for="(item2,index2) in item.list" :key="index2">
            <div>{{item2.label}}</div>
            <div style="padding-top: 10px;">{{item2.value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  // 例如：import 《组件名称》 from '《组件路径》'
  import homeManager from "@/request/homeManager.js";
  export default {
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data () {
      // 这里存放数据
      return {
        requst: {
          module: '',
          url: '',
        },
        query: {},
        dataList: [],
        activeNames: '1',
        dataInfo: null,
        skinType: null,
        colorType: null,
        detectResultId: 0,
        skinLevel:[],
      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created () {
      this.getBySourceType()
      this.getBySourceTypes()
      this.getBySourceTypeLevel()
      this.detectResultId = this.$route.query.detectResultId
      this.getByDetectResultId()
      
      
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted () { },
    // 方法集合
    methods: {
      //获取详情
      async getByDetectResultId () {
        try {
          let { code, data } = await homeManager.getByDetectResultId({ detectResultId: this.detectResultId })
          if (code == 200) {
            this.dataInfo = data
            let dictItemName = ''
            if(data.pockmarkLevel){
              this.skinLevel.forEach(item => {
                if(item.dictItemCode == data.pockmarkLevel){
                  dictItemName = item.dictItemName
                }
              });
            }
            this.dataList = [{
              name: '痘痘',
              value: data.pockmarkScore,
              img: data.pockmarkImg,
              list: [{
                label: '程度',
                value: dictItemName
              }, {
                label: '痘痘数量',
                value: data.pockmarkCount
              }, {
                label: '痘痘得分',
                value: data.pockmarkDdScore
              }, {
                label: '痘印得分',
                value: data.pockmarkDyScore
              }]
            }, {
              name: '黑眼圈',
              value: data.darkCircleScore,
              list: [],
              img: data.darkCircleImg
            }, {
              name: '黑头',
              value: data.blackheadScore,
              list: [{
                label: '面积占比',
                value: this.$util.fmtToFix(data.blackheadArea, 3)
              }],
              img: data.blackheadImg
            }, {
              name: '棕色斑',
              value: data.chloasmaScore,
              list: [{
                label: '数量',
                value: data.chloasmaCount
              }],
              img: data.chloasmaImg
            }, {
              name: '毛孔',
              value: data.poreScore,
              list: [{
                label: '面积占比',
                value: this.$util.fmtToFix(data.poreArea, 3)
              }],
              img: data.poreImg
            }]
          }
        } catch (error) {
          //
        }
      },

      //肤色
      async getBySourceType () {
        try {
          let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerAiDetectSkinColor' })
          if (code == 200) {
            this.colorType = data
          }
        } catch (error) {
          //
        }
      },
      //肤色
      async getBySourceTypes () {
        try {
          let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerAiDetectSkinType' })
          if (code == 200) {
            this.skinType = data
          }
        } catch (error) {
          //
        }
      },
      //程度
      async getBySourceTypeLevel () {
        try {
          let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerAiDetectLevel' })
          if (code == 200) {
            this.skinLevel = data
          }
        } catch (error) {
          //
        }
      },
      close () {
        this.$router.go(-1);
      },
    },
    filters: {
      getStateName (value, arr) {
        if (arr) {
          var array = arr.filter((obj) => {
            return value === obj.dictItemCode;
          });
          if (array.length !== 0) {
            return array[0].dictItemName;
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
    },
    // 生命周期 - 创建之前
    beforeCreate () { },
    // 生命周期 - 挂载之前
    beforeMount () { },
    // 生命周期 - 更新之前
    beforeUpdate () { },
    // 生命周期 - 更新之后
    updated () { },
    // 生命周期 - 销毁之前
    beforeDestroy () { },
    // 生命周期 - 销毁完成
    destroyed () { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated () { },
  }
</script>

<style scoped>
  .container {
    min-height: 100vh;
    background: #f7f7f7;
    padding: 20px;
    box-sizing: border-box;
  }

  .header {
    margin: 20px 0;
  }

  .header span {
    font-size: 30px;
    font-weight: 600;
  }

  .btn {
    font-size: 30px;
  }

  .tables {
    font-size: 20px;
  }

  .con {
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .tit {
    font-size: 20px;
  }

  .tag {
    font-size: 20px;
  }

  .imgs {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 20px;
    margin-right: 20px;
  }

  .titles {
    font-size: 20px;
  }

  .info {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background: #409eff;
    color: #fafafa;
    border-radius: 10px;
  }
</style>