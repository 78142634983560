var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "追加私密档案",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
        },
        on: { "click-left": _vm.onClickNavLeftBtn },
      }),
      _c("div", { staticClass: "w-1 page-data-content-part-box" }, [
        _c(
          "div",
          { staticClass: "w-1 file-content-input-part-box" },
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                autosize: { minRows: 4, maxRows: 8 },
                placeholder: "请输入私密档案内容",
              },
              model: {
                value: _vm.addFrom.fileContent,
                callback: function ($$v) {
                  _vm.$set(_vm.addFrom, "fileContent", $$v)
                },
                expression: "addFrom.fileContent",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-1 file-btn-part-wrap-box t-a-r" },
          [
            _c(
              "el-button",
              {
                staticClass: "submit-btn-box",
                attrs: { type: "primary", round: "" },
                on: { click: _vm.clickSubmitBtn },
              },
              [_vm._v("提交")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }