var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "综合皮肤档案",
          "left-arrow": "",
          fixed: "",
          placeholder: "",
        },
        on: { "click-left": _vm.close },
      }),
      _vm.tableData.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "con" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { title: "", column: 1, border: "" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "id:" } },
                          [_vm._v(_vm._s(item.oid))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "更新备注:" } },
                          [_vm._v(_vm._s(item.abnormalSkinFields))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        staticClass: "tables",
                        staticStyle: { width: "100%" },
                        attrs: { size: "mini", data: _vm.tableInfo },
                      },
                      _vm._l(item.classifyRootList, function (item, index) {
                        return _c(
                          "el-table-column",
                          {
                            key: index,
                            attrs: {
                              prop: "date",
                              label: item.classifyName,
                              "min-width": "80",
                              align: "center",
                            },
                          },
                          [
                            item.skinFeatureFieldList.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    item.skinFeatureFieldList,
                                    function (items, indexs) {
                                      return _c("el-table-column", {
                                        key: indexs,
                                        attrs: {
                                          prop: "province",
                                          label: items.skinFeatureFieldNameDesc,
                                          "min-width": "80",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.$index == 0
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          { class: ["close"] },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  items.value
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              }),
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  "pager-count": 5,
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.totalSize,
                },
                on: { "current-change": _vm.changepage },
              }),
            ],
            2
          )
        : _c("el-empty"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }