<template>
  <div class='container'>
    <van-nav-bar title="综合皮肤档案" left-arrow fixed placeholder @click-left="close" />
    <div v-if="tableData.length>0">
      <div class="con" v-for="(item,index) in tableData" :key="index">
        <el-descriptions title="" :column="1" border>
          <el-descriptions-item label="id:">{{item.oid}}</el-descriptions-item>
          <el-descriptions-item label="更新备注:">{{item.abnormalSkinFields}}</el-descriptions-item>
        </el-descriptions>
        <el-table size="mini" class="tables" :data="tableInfo" style="width: 100%;">
          <el-table-column prop="date" :label="item.classifyName" min-width="80" align="center" v-for="(item,index) in item.classifyRootList" :key="index">
            <div v-if="item.skinFeatureFieldList.length>0">
              <el-table-column prop="province" :label="items.skinFeatureFieldNameDesc" min-width="80" v-for="(items,indexs) in item.skinFeatureFieldList" :key="indexs" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.$index==0">
                    <div :class="['close']">
                      <span>{{items.value}}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination style="text-align: right;" :pager-count="5" background layout="prev, pager, next" :total="totalSize" @current-change="changepage">
      </el-pagination>
    </div>
    <el-empty v-else></el-empty>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from "@/request/homeManager.js";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      requst: {
        module: '',
        url: '',
      },
      query: {},
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      totalSize: 0,
      tableInfo: [{
        degree: '',
      }],
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created () {
    await this.pageOrder()
    console.log(this.tableData)
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    async pageOrder () {
      try {
        let { code, data } = await homeManager.skinSummaryPage({ pageSize: this.pageSize, currentPage: this.currentPage, custId: this.$store.getters.custId })
        if (code == 200) {
          this.tableData = data.dataList
          this.totalSize = data.totalSize
        }
      } catch (error) {
        console.log(error)
        //
      }
    },
    //改变页码
    changepage (val) {
      this.currentPage = val
      this.pageOrder()
    },
    close () {
      this.$router.go(-1);
    },
  },
  filters: {
    getStateName (value, arr) {
      if (arr) {
        var array = arr.filter((obj) => {
          return value === obj.dictItemCode;
        });
        if (array.length !== 0) {
          return array[0].dictItemName;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped>
.container {
  min-height: 100vh;
  background: #f7f7f7;
  padding: 20px;
  box-sizing: border-box;
}

.header {
  margin: 20px 0;
}

.header span {
  font-size: 30px;
  font-weight: 600;
}

.btn {
  font-size: 30px;
}

.tables {
  font-size: 20px;
}

.con {
  padding: 20px;
  margin-bottom: 20px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 10px;
}

.el-descriptions {
  margin-bottom: 20px;
}

.close {
  padding: 5px 0;
  border-radius: 5px;
}

.on {
  color: #fff;
}

::v-deep .van-nav-bar--fixed {
  z-index: 999;
}
::v-deep .el-table .el-table__cell {
  padding: 5px 0;
}
::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 10px;
}
</style>