
export const skinTestResults = () => {
  return {
    "createTime": "2024-02-23 17:35:38.000", //拍摄时间
    "cusOpenId": "xxxx", //顾客第三方系统ID
    "deviceNo": "MC2000-ZYX", //操作设备
    "diagnosisSkinList": [{
      "acneArea": 3911.5, //卟啉面积
      "acneNum": 666, //卟啉数量
      "acneRatio": 0.00285, //卟啉占比
      "acneScore": 0.77295, //卟啉得分
      "agingIndex": null, //衰老指数
      "brownspotAgingIndex": 1, //棕斑衰老指数
      "brownspotArea": 444606.5, //棕斑面积
      "brownspotNum": 286, //棕斑数量
      "brownspotRatio": 0.32341, //棕斑面积占比
      "brownspotScore": 0.60433, //棕斑得分
      "brownspotWeight": null, //棕斑衰老权重
      "diagnosisSkinDetailList": [{
        "area": 11656, //面积
        "areaRatio": 0.00847854, //面积占比
        "counts": 50, //数量
        "degreeLevel": 2, //程度等级 （1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 430088.5, //面积
        "areaRatio": 0.31284511, //面积占比
        "counts": 231, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 2862, //面积
        "areaRatio": 0.00208181, //面积占比
        "counts": 5, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 129505, //面积
        "areaRatio": 0.09420156, //面积占比
        "counts": 124, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 41449.5, //面积
        "areaRatio": 0.03015024, //面积占比
        "counts": 92, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 9558, //面积
        "areaRatio": 0.00695246, //面积占比
        "counts": 18, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 7389.02587891, //面积
        "areaRatio": 0.00537476, //面积占比
        "counts": 48, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 22619.46679688, //面积
        "areaRatio": 0.01645333, //面积占比
        "counts": 800, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 90477.8671875, //面积
        "areaRatio": 0.06581333, //面积占比
        "counts": 1152, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 5952, //面积
        "areaRatio": 0.01853241, //面积占比
        "counts": 16, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 7688, //面积
        "areaRatio": 0.0239377, //面积占比
        "counts": 6, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }], //症状明细数据
      "direction": -1, //面部方向(-1左,0中,1右)
      "imgAcnePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/acne.png", //卟啉结果PNG
      "imgAging": null, //衰老预测图
      "imgBetweeneyePng": "",
      "imgBloodmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/bloodmap.jpg", //近红外图/血丝提取图
      "imgBrownHotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/brownHotmap.jpg", //棕区热力图
      "imgBrownSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/brownSpot.png", //棕色斑结果PNG
      "imgBrownmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/brownMap.jpg", //棕区图
      "imgCormouthPng": "", //口角纹结果PNG
      "imgCross": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/cross.jpg", //交叉偏振光图
      "imgDaylight": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/daylight.jpg", //RGB光图
      "imgDeepGraySpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/deepGraySpot.jpg", //深斑Png
      "imgDeepSpotPng": "", //UV斑Png
      "imgForeheadPng": "", //抬头纹结果PNG
      "imgGlabellarPng": "", //眉间纹结果PNG
      "imgNasofoldsPng": "", //法令纹结果PNG
      "imgParallel": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/parallel.jpg", //平行偏振光图
      "imgPorePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/pore.png", //毛孔结果PNG
      "imgRedhotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/redhotmap.jpg", //红区热力图
      "imgRedmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/redmap.jpg", //红区图
      "imgSensitiveAreaPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/sensitiveArea.png", //敏感区结果PNG
      "imgSidePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/side.png", //鱼尾纹结果PNG
      "imgSurfaceSpotAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/surfaceSpotAging.jpg", //色斑衰老预测图
      "imgSurfaceSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/surfaceSpot.png", //表面色斑结果Png
      "imgTexturePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/texture.png", //纹理结果PNG
      "imgUndereyePng": "", //眶周纹结果PNG
      "imgUv": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/uv.jpg", //紫外光图
      "imgUvHighContrast": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/uvHighContrast.jpg", //黑白图
      "imgUvSpecial": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/uvSpecial.jpg", //绿图
      "isFinished": 0, //是否全部拍摄完成
      "jsonAging": null, //衰老预测Json
      "poreArea": 120486.35938, //毛孔面积
      "poreNum": 2000, //毛孔数量
      "poreRatio": 0.08764, //毛孔面积占比
      "poreScore": 0.06027, //毛孔得分
      "redspotArea": 180512.5, //红区面积
      "redspotNum": 234, //红区数量
      "redspotRatio": 0.1313, //红区面积占比
      "redspotScore": 0.42257, //红区得分
      "skinAge": 19, //肌肤年龄
      "skinColor": "DARK", //肤色(黑色:BLACK, 棕色:BRONZE, 深色:DARK, 小麦色:WHEAT, 自然色:NATURE, 灰色:GREY, 白色:WHITE, 红色:RED)
      "skinScore": 0.40517, //综合得分
      "surfacespotArea": 112050.5, //表素面积
      "surfacespotNum": 181, //表素数量
      "surfacespotRatio": 0.08151, //表素面积占比
      "surfacespotScore": 0.35412, //表素得分
      "textureArea": 192463, //纹理面积
      "textureNum": 2293, //纹理数量
      "textureRatio": 0.14, //纹理面积占比
      "textureScore": 0, //纹理得分
      "uvdeepspotArea": 105999, //深斑面积
      "uvdeepspotNum": 171, //深斑数量
      "uvdeepspotRatio": 0.0771, //深斑面积占比
      "uvdeepspotScore": 0.03125, //深斑得分
      "uvspotArea": null, //UV斑面积
      "uvspotNum": null, //UV斑数量
      "uvspotRatio": null, //UV斑面积占比
      "uvspotScore": null, //UV斑得分
      "wrinkleBetweeneyeAgingIndex": null, //眼间纹衰老指数
      "wrinkleBetweeneyeScore": null, //眼间纹得分
      "wrinkleBetweeneyeWeight": null, //眼间纹衰老权重
      "wrinkleCormouthAgingIndex": null, //口角纹衰老指数
      "wrinkleCormouthScore": null, //口角纹得分
      "wrinkleCormouthWeight": null, //口角纹衰老权重
      "wrinkleForeheadAgingIndex": null, //抬头纹衰老指数
      "wrinkleForeheadScore": null, //抬头纹得分
      "wrinkleForeheadWeight": null, //抬头纹衰老权重
      "wrinkleGlabellarAgingIndex": null, //眉间纹衰老指数
      "wrinkleGlabellarScore": null, //眉间纹得分
      "wrinkleGlabellarWeight": null, //眉间纹衰老权重
      "wrinkleNasofoldsAgingIndex": null, //法令纹衰老指数
      "wrinkleNasofoldsScore": null, //法令纹得分
      "wrinkleNasofoldsWeight": null, //法令纹衰老权重
      "wrinkleScore": 0.696, //皱纹得分
      "wrinkleSideAgingIndex": 2, //鱼尾纹衰老指数
      "wrinkleSideScore": 0.696, //鱼尾纹得分
      "wrinkleSideWeight": null, //鱼尾纹衰老权重
      "wrinkleUndereyeAgingIndex": null, //眶周纹衰老指数
      "wrinkleUndereyeScore": null, //眶周纹得分
      "wrinkleUndereyeWeight": null //眶周纹衰老权重
    }, {
      "acneArea": 6831, //卟啉面积
      "acneNum": 851, //卟啉数量
      "acneRatio": 0.00276, //卟啉占比
      "acneScore": 0.7757, //卟啉得分
      "agingIndex": 1, //衰老指数
      "brownspotAgingIndex": 1, //棕斑衰老指数
      "brownspotArea": 720920.5, //棕斑面积
      "brownspotNum": 589, //棕斑数量
      "brownspotRatio": 0.29148, //棕斑面积占比
      "brownspotScore": 0.76402, //棕斑得分
      "brownspotWeight": 0.12731, //棕斑衰老权重
      "diagnosisSkinDetailList": [{
        "area": 3148.5, //面积
        "areaRatio": 0.00127301, //面积占比
        "counts": 11, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 682051.5, //面积
        "areaRatio": 0.27576903, //面积占比
        "counts": 386, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 35720.5, //面积
        "areaRatio": 0.01444261, //面积占比
        "counts": 192, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 74413, //面积
        "areaRatio": 0.03008688, //面积占比
        "counts": 175, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 283170.5, //面积
        "areaRatio": 0.1144923, //面积占比
        "counts": 241, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 9030, //面积
        "areaRatio": 0.00365104, //面积占比
        "counts": 21, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 115846.2265625, //面积
        "areaRatio": 0.04687358, //面积占比
        "counts": 1475, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 31441.05859375, //面积
        "areaRatio": 0.01272165, //面积占比
        "counts": 1112, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 16933.18359375, //面积
        "areaRatio": 0.00685149, //面积占比
        "counts": 110, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "09" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 1857, //面积
        "areaRatio": 0.00196003, //面积占比
        "counts": 10, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "09" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 3877, //面积
        "areaRatio": 0.00409211, //面积占比
        "counts": 2, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "09" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 9431.5, //面积
        "areaRatio": 0.00995478, //面积占比
        "counts": 11, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "09" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "10" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "10" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "10" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 1237.5, //面积
        "areaRatio": 0.00773525, //面积占比
        "counts": 3, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "10" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 379, //面积
        "areaRatio": 0.00187993, //面积占比
        "counts": 1, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "11" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "11" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "11" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 7016, //面积
        "areaRatio": 0.03480107, //面积占比
        "counts": 21, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "11" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "12" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "12" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 13751.5, //面积
        "areaRatio": 0.02241297, //面积占比
        "counts": 26, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "12" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 2480.5, //面积
        "areaRatio": 0.00404286, //面积占比
        "counts": 9, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "12" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "14" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 6, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "14" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "14" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "14" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 5, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "14" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "14" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "15" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 4237, //面积
        "areaRatio": 0.07680734, //面积占比
        "counts": 2, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "15" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "15" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 5, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "15" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "15" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 6, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "15" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }], //症状明细数据
      "direction": 0, //面部方向(-1左,0中,1右)
      "imgAcnePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/acne.png", //卟啉结果PNG
      "imgAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/aging.png", //衰老预测图
      "imgBetweeneyePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/betweeneye.png",
      "imgBloodmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/bloodmap.jpg", //近红外图/血丝提取图
      "imgBrownHotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/brownHotmap.jpg", //棕区热力图
      "imgBrownSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/brownSpot.png", //棕色斑结果PNG
      "imgBrownmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/brownMap.jpg", //棕区图
      "imgCormouthPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/cormouth.png", //口角纹结果PNG
      "imgCross": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/cross.jpg", //交叉偏振光图
      "imgDaylight": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/daylight.jpg", //RGB光图
      "imgDeepGraySpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/deepGraySpot.jpg", //深斑Png
      "imgDeepSpotPng": "", //UV斑Png
      "imgForeheadPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/forehead.png", //抬头纹结果PNG
      "imgGlabellarPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/glabellar.png", //眉间纹结果PNG
      "imgNasofoldsPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/nasofolds.png", //法令纹结果PNG
      "imgParallel": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/parallel.jpg", //平行偏振光图
      "imgPorePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/pore.png", //毛孔结果PNG
      "imgRedhotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/redhotmap.jpg", //红区热力图
      "imgRedmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/redmap.jpg", //红区图
      "imgSensitiveAreaPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/sensitiveArea.png", //敏感区结果PNG
      "imgSidePng": "", //鱼尾纹结果PNG
      "imgSurfaceSpotAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/surfaceSpotAging.jpg", //色斑衰老预测图
      "imgSurfaceSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/surfaceSpot.png", //表面色斑结果Png
      "imgTexturePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/texture.png", //纹理结果PNG
      "imgUndereyePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/undereye.png", //眶周纹结果PNG
      "imgUv": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/uv.jpg", //紫外光图
      "imgUvHighContrast": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/uvHighContrast.jpg", //黑白图
      "imgUvSpecial": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/uvSpecial.jpg", //绿图
      "isFinished": 1, //是否全部拍摄完成
      "jsonAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/aging.json", //衰老预测Json
      "poreArea": 164220.46875, //毛孔面积
      "poreNum": 2697, //毛孔数量
      "poreRatio": 0.06645, //毛孔面积占比
      "poreScore": 0.38192, //毛孔得分
      "redspotArea": 366613.5, //红区面积
      "redspotNum": 437, //红区数量
      "redspotRatio": 0.14823, //红区面积占比
      "redspotScore": 0.53416, //红区得分
      "skinAge": 18, //肌肤年龄
      "skinColor": "DARK", //肤色(黑色:BLACK, 棕色:BRONZE, 深色:DARK, 小麦色:WHEAT, 自然色:NATURE, 灰色:GREY, 白色:WHITE, 红色:RED)
      "skinScore": 0.49508, //综合得分
      "surfacespotArea": 263243, //表素面积
      "surfacespotNum": 424, //表素数量
      "surfacespotRatio": 0.10644, //表素面积占比
      "surfacespotScore": 0.18865, //表素得分
      "textureArea": 270513, //纹理面积
      "textureNum": 3249, //纹理数量
      "textureRatio": 0.10937, //纹理面积占比
      "textureScore": 0, //纹理得分
      "uvdeepspotArea": 201358, //深斑面积
      "uvdeepspotNum": 300, //深斑数量
      "uvdeepspotRatio": 0.08141, //深斑面积占比
      "uvdeepspotScore": 0.01019, //深斑得分
      "uvspotArea": null, //UV斑面积
      "uvspotNum": null, //UV斑数量
      "uvspotRatio": null, //UV斑面积占比
      "uvspotScore": null, //UV斑得分
      "wrinkleBetweeneyeAgingIndex": 1, //眼间纹衰老指数
      "wrinkleBetweeneyeScore": 0.82807, //眼间纹得分
      "wrinkleBetweeneyeWeight": 0.14815, //眼间纹衰老权重
      "wrinkleCormouthAgingIndex": 1, //口角纹衰老指数
      "wrinkleCormouthScore": 0.66333, //口角纹得分
      "wrinkleCormouthWeight": 0, //口角纹衰老权重
      "wrinkleForeheadAgingIndex": 3, //抬头纹衰老指数
      "wrinkleForeheadScore": 0.49175, //抬头纹得分
      "wrinkleForeheadWeight": 0.24191, //抬头纹衰老权重
      "wrinkleGlabellarAgingIndex": 1, //眉间纹衰老指数
      "wrinkleGlabellarScore": 0.95866, //眉间纹得分
      "wrinkleGlabellarWeight": 0.152, //眉间纹衰老权重
      "wrinkleNasofoldsAgingIndex": 0, //法令纹衰老指数
      "wrinkleNasofoldsScore": 0.99, //法令纹得分
      "wrinkleNasofoldsWeight": 0.06375, //法令纹衰老权重
      "wrinkleScore": 0.8024, //皱纹得分
      "wrinkleSideAgingIndex": null, //鱼尾纹衰老指数
      "wrinkleSideScore": null, //鱼尾纹得分
      "wrinkleSideWeight": 0, //鱼尾纹衰老权重
      "wrinkleUndereyeAgingIndex": 1, //眶周纹衰老指数
      "wrinkleUndereyeScore": 0.88259, //眶周纹得分
      "wrinkleUndereyeWeight": 0.1349 //眶周纹衰老权重
    }, {
      "acneArea": 4109, //卟啉面积
      "acneNum": 794, //卟啉数量
      "acneRatio": 0.00258, //卟啉占比
      "acneScore": 0.79303, //卟啉得分
      "agingIndex": null, //衰老指数
      "brownspotAgingIndex": 1, //棕斑衰老指数
      "brownspotArea": 390115, //棕斑面积
      "brownspotNum": 312, //棕斑数量
      "brownspotRatio": 0.24542, //棕斑面积占比
      "brownspotScore": 0.77803, //棕斑得分
      "brownspotWeight": null, //棕斑衰老权重
      "diagnosisSkinDetailList": [{
        "area": 384137.5, //面积
        "areaRatio": 0.24166264, //面积占比
        "counts": 262, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 4954, //面积
        "areaRatio": 0.00311658, //面积占比
        "counts": 43, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 1023.5, //面积
        "areaRatio": 0.00064389, //面积占比
        "counts": 7, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "02" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 135005, //面积
        "areaRatio": 0.08493225, //面积占比
        "counts": 153, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 4343.5, //面积
        "areaRatio": 0.00273252, //面积占比
        "counts": 14, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 44905, //面积
        "areaRatio": 0.02824994, //面积占比
        "counts": 121, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "04" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 12007.16699219, //面积
        "areaRatio": 0.00755376, //面积占比
        "counts": 78, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 27341.28125, //面积
        "areaRatio": 0.01720052, //面积占比
        "counts": 967, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 100609.5, //面积
        "areaRatio": 0.06329389, //面积占比
        "counts": 1281, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "07" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 3, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 9038.5, //面积
        "areaRatio": 0.02365703, //面积占比
        "counts": 28, //数量
        "degreeLevel": 1, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 0, //面积
        "areaRatio": 0, //面积占比
        "counts": 0, //数量
        "degreeLevel": 4, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }, {
        "area": 4204, //面积
        "areaRatio": 0.01100339, //面积占比
        "counts": 4, //数量
        "degreeLevel": 2, //程度等级（1轻，2中，3重）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
        "symptom": "13" //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
      }], //症状明细数据
      "direction": 1, //面部方向(-1左,0中,1右)
      "imgAcnePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/acne.png", //卟啉结果PNG
      "imgAging": null, //衰老预测图
      "imgBetweeneyePng": "",
      "imgBloodmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/bloodmap.jpg", //近红外图/血丝提取图
      "imgBrownHotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/brownHotmap.jpg", //棕区热力图
      "imgBrownSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/brownSpot.png", //棕色斑结果PNG
      "imgBrownmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/brownMap.jpg", //棕区图
      "imgCormouthPng": "", //口角纹结果PNG
      "imgCross": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/cross.jpg", //交叉偏振光图
      "imgDaylight": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/daylight.jpg", //RGB光图
      "imgDeepGraySpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/deepGraySpot.jpg", //深斑Png
      "imgDeepSpotPng": "", //UV斑Png
      "imgForeheadPng": "", //抬头纹结果PNG
      "imgGlabellarPng": "", //眉间纹结果PNG
      "imgNasofoldsPng": "", //法令纹结果PNG
      "imgParallel": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/parallel.jpg", //平行偏振光图
      "imgPorePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/pore.png", //毛孔结果PNG
      "imgRedhotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/redhotmap.jpg", //红区热力图
      "imgRedmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/redmap.jpg", //红区图
      "imgSensitiveAreaPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/sensitiveArea.png", //敏感区结果PNG
      "imgSidePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/side.png", //鱼尾纹结果PNG
      "imgSurfaceSpotAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/surfaceSpotAging.jpg", //色斑衰老预测图
      "imgSurfaceSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/surfaceSpot.png", //表面色斑结果Png
      "imgTexturePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/texture.png", //纹理结果PNG
      "imgUndereyePng": "", //眶周纹结果PNG
      "imgUv": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/uv.jpg", //紫外光图
      "imgUvHighContrast": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/uvHighContrast.jpg", //黑白图
      "imgUvSpecial": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/uvSpecial.jpg", //绿图
      "isFinished": 0, //是否全部拍摄完成
      "jsonAging": null, //衰老预测Json
      "poreArea": 139957.95313, //毛孔面积
      "poreNum": 2326, //毛孔数量
      "poreRatio": 0.08805, //毛孔面积占比
      "poreScore": 0.07721, //毛孔得分
      "redspotArea": 184253.5, //红区面积
      "redspotNum": 288, //红区数量
      "redspotRatio": 0.11591, //红区面积占比
      "redspotScore": 0.59305, //红区得分
      "skinAge": 19, //肌肤年龄
      "skinColor": "DARK", //肤色(黑色:BLACK, 棕色:BRONZE, 深色:DARK, 小麦色:WHEAT, 自然色:NATURE, 灰色:GREY, 白色:WHITE, 红色:RED)
      "skinScore": 0.45287, //综合得分
      "surfacespotArea": 150865, //表素面积
      "surfacespotNum": 256, //表素数量
      "surfacespotRatio": 0.09491, //表素面积占比
      "surfacespotScore": 0.31041, //表素得分
      "textureArea": 241184, //纹理面积
      "textureNum": 2821, //纹理数量
      "textureRatio": 0.15173, //纹理面积占比
      "textureScore": 0, //纹理得分
      "uvdeepspotArea": 123465, //深斑面积
      "uvdeepspotNum": 180, //深斑数量
      "uvdeepspotRatio": 0.07767, //深斑面积占比
      "uvdeepspotScore": 0.03472, //深斑得分
      "uvspotArea": null, //UV斑面积
      "uvspotNum": null, //UV斑数量
      "uvspotRatio": null, //UV斑面积占比
      "uvspotScore": null, //UV斑得分
      "wrinkleBetweeneyeAgingIndex": null, //眼间纹衰老指数
      "wrinkleBetweeneyeScore": null, //眼间纹得分
      "wrinkleBetweeneyeWeight": null, //眼间纹衰老权重
      "wrinkleCormouthAgingIndex": null, //口角纹衰老指数
      "wrinkleCormouthScore": null, //口角纹得分
      "wrinkleCormouthWeight": null, //口角纹衰老权重
      "wrinkleForeheadAgingIndex": null, //抬头纹衰老指数
      "wrinkleForeheadScore": null, //抬头纹得分
      "wrinkleForeheadWeight": null, //抬头纹衰老权重
      "wrinkleGlabellarAgingIndex": null, //眉间纹衰老指数
      "wrinkleGlabellarScore": null, //眉间纹得分
      "wrinkleGlabellarWeight": null, //眉间纹衰老权重
      "wrinkleNasofoldsAgingIndex": null, //法令纹衰老指数
      "wrinkleNasofoldsScore": null, //法令纹得分
      "wrinkleNasofoldsWeight": null, //法令纹衰老权重
      "wrinkleScore": 0.70973, //皱纹得分
      "wrinkleSideAgingIndex": 1, //鱼尾纹衰老指数
      "wrinkleSideScore": 0.70973, //鱼尾纹得分
      "wrinkleSideWeight": null, //鱼尾纹衰老权重
      "wrinkleUndereyeAgingIndex": null, //眶周纹衰老指数
      "wrinkleUndereyeScore": null, //眶周纹得分
      "wrinkleUndereyeWeight": null //眶周纹衰老权重
    }], //皮肤检测结果
    "directions": "111" //面部方向(111)左中右
  }
}

export const skinTestImgTab = (face_data_obj) => {
  return [{
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgDaylight,   //底图
    skinBaseTestUrl:'',
    skinDestUrl: '',   //叠加图
    skinDestTestUrl:'',
    skinText: '原图',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgCross,   //底图-症状
    skinBaseTestUrl: face_data_obj.imgCross,   //底图-预测
    skinDestUrl: face_data_obj.imgSurfaceSpotPng,   //叠加图-症状
    skinDestTestUrl: face_data_obj.imgSurfaceSpotAging,   //叠加图-预测
    skinText: '表素',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgBrownmap,   //底图
    skinBaseTestUrl:'',
    skinDestUrl: face_data_obj.imgBrownSpotPng,   //叠加图
    skinDestTestUrl:'',
    skinText: '棕斑',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgUv,   //底图
    skinBaseTestUrl:'',
    skinDestUrl: face_data_obj.imgUvHighContrast,   //叠加图
    skinDestTestUrl:'',
    skinText: '深斑',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgRedmap,   //底图
    skinBaseTestUrl:'',
    skinDestUrl: face_data_obj.imgSensitiveAreaPng,   //叠加图
    skinDestTestUrl:'',
    skinText: '红区',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgParallel,   //底图
    skinBaseTestUrl:'',
    skinDestUrl: face_data_obj.imgPorePng,   //叠加图
    skinDestTestUrl:'',
    skinText: '毛孔',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgUv,   //底图
    skinBaseTestUrl:'',
    skinDestUrl: face_data_obj.imgAcnePng,   //叠加图
    skinDestTestUrl:'',
    skinText: '粉刺',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgParallel,   //底图-症状
    skinBaseTestUrl: face_data_obj.imgParallel,   //底图-预测
    skinDestUrl: face_data_obj.imgTexturePng,   //叠加图-症状
    skinDestTestUrl: face_data_obj.imgAging,   //叠加图-预测
    skinText: '纹理',
  }]
}