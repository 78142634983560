var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container user-test-report-box fl-s-c" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "检测图",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
        },
        on: { "click-left": _vm.onClickNavLeftBtn },
      }),
      _vm.isShowPageData
        ? _c(
            "div",
            {
              staticClass: "w-1 flex-1 page-content-part-wrap-box fl-b-b",
              class: { "page-images-ff": _vm.fmtIsShowImgWhiteBg },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-1 flex-1 fl-c relative skin-test-results-images-change-slider-dialog-part-wrap-box",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1 flex-1 fl-c skin-test-results-images-dialog-data-btn-show-content-part-box",
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "areaBoxRef",
                          staticClass:
                            "w-1 fl-c skin-test-results-images-movable-area-part-box",
                        },
                        [
                          _c(
                            "div",
                            {
                              ref: "dragBoxRef",
                              staticClass:
                                "fl-c skin-test-results-images-movable-div-part-box",
                              style: {
                                transform: `scale(${_vm.scaleOffsetVal.scaleValue}) translate(${_vm.scaleOffsetVal.offsetValueX}px, ${_vm.scaleOffsetVal.offsetValueY}px)`,
                              },
                              on: {
                                wheel: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleMouseWheelScaleScroll.apply(
                                    null,
                                    arguments
                                  )
                                },
                                mousedown: _vm.handleMouseDownMove,
                                touchmove: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleTouchMoveCallback.apply(
                                    null,
                                    arguments
                                  )
                                },
                                touchstart: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleTouchStartCallback.apply(
                                    null,
                                    arguments
                                  )
                                },
                                touchend: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleTouchEndCallback.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  ref: "imgRef",
                                  staticClass:
                                    "relative skin-test-results-images-box",
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "iamges-style images-bottom-layer",
                                    attrs: {
                                      alt: "底层图片",
                                      src: _vm.skinImgCurrentObj[
                                        _vm.skinTestType == 0 ||
                                        _vm.skinTestType == 1
                                          ? "skinBaseUrl"
                                          : "skinBaseTestUrl"
                                      ],
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1 relative overlay-layer-style-part-box",
                                    },
                                    [
                                      _vm.fmtIsShowChangeBswl
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "overlay-layer-box base-map-images-box",
                                              style: {
                                                width: `${_vm.imgSlideX}%`,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "iamges-style images-base-map",
                                                attrs: {
                                                  alt: "底图",
                                                  src: _vm.skinImgCurrentObj[
                                                    _vm.skinTestType == 1
                                                      ? "skinBaseUrl"
                                                      : "skinBaseTestUrl"
                                                  ],
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.skinTestBigType != "原图" &&
                                      _vm.skinTestType != 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-1 h-1 overlay-layer-box stack-iamges-box",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "iamges-style iamges-stack",
                                                attrs: {
                                                  alt: "叠加图片",
                                                  src: _vm.skinImgCurrentObj[
                                                    _vm.skinTestType == 1
                                                      ? "skinDestUrl"
                                                      : "skinDestTestUrl"
                                                  ],
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1 skin-test-results-type-change-slider-part-wrap-box fr-c-e",
                    },
                    [
                      _vm.fmtIsShowChangeSlider
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-1 h-1 skin-test-results-type-change-slider-content-part-box fl-c",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1 fr-c skin-test-results-type-change-part-box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "results-type-change-box fr-b-c",
                                    },
                                    [
                                      _vm.fmtIsShowChangeOther
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "change-type-item fr-c",
                                              class: {
                                                active: _vm.skinTestType == 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.clickResultChangeTypeItemBtn(
                                                    0
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("原图")]
                                          )
                                        : _vm._e(),
                                      _vm.fmtIsShowChangeBswl
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "change-type-item fr-c",
                                              class: {
                                                active: _vm.skinTestType == 2,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.clickResultChangeTypeItemBtn(
                                                    2
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("预测")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "change-type-item fr-c",
                                          class: {
                                            active: _vm.skinTestType == 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickResultChangeTypeItemBtn(
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("症状")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.fmtIsShowChangeBswl
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1 skin-test-results-slider-part-box fr-c",
                                    },
                                    [
                                      _c("van-slider", {
                                        attrs: {
                                          min: 0,
                                          max: 100,
                                          "inactive-color":
                                            "rgba(255, 255, 255, 0.4)",
                                          "active-color": "#ffffff",
                                        },
                                        model: {
                                          value: _vm.imgSlideX,
                                          callback: function ($$v) {
                                            _vm.imgSlideX = $$v
                                          },
                                          expression: "imgSlideX",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-1 page-bootom-skin-test-result-imgTab-part-wrap-box fr-c",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "h-1 bootom-skin-test-result-imgTab-scroll",
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "bootom-skin-test-result-imgTab-content-part-box fr-s-c",
                        },
                        _vm._l(_vm.skinImgTabList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "skin-test-imgTab-item-box fl-c",
                              class: {
                                active: _vm.skinTestBigType == item.skinText,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgTab-item-content-box fl-c",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clickSkinTestImgTabBtnItem(
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "imgTab-item-small-img-box fr-c relative",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "iamges-small-base",
                                        attrs: {
                                          alt: "底图",
                                          src: item.skinBaseUrl,
                                        },
                                      }),
                                      item.skinText != "原图"
                                        ? _c("img", {
                                            staticClass: "iamges-small-dest",
                                            attrs: {
                                              alt: "叠加图",
                                              src: item.skinDestUrl,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("p", { staticClass: "imgTab-item-text" }, [
                                    _vm._v(_vm._s(item.skinText)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.isShowPageData
        ? _c(
            "div",
            {
              staticClass: "page-right-top-fixed-btn-show-part-wrap-box",
              class: { "right-top-fixed-ff": _vm.fmtIsShowImgWhiteBg },
            },
            [
              _c(
                "ul",
                {
                  staticClass:
                    "right-top-fixed-btn-show-content-part-box fl-s-c",
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: {
                        active: _vm.faceDirection == -1,
                        disable: !_vm.fmtIsDirection(-1),
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(1, -1)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "左脸",
                            src:
                              _vm.faceDirection == -1
                                ? _vm.left_face_active
                                : _vm.left_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("左脸"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: {
                        active: _vm.faceDirection == 0,
                        disable: !_vm.fmtIsDirection(0),
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(1, 0)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "正脸",
                            src:
                              _vm.faceDirection == 0
                                ? _vm.main_face_active
                                : _vm.main_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("正脸"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: {
                        active: _vm.faceDirection == 1,
                        disable: !_vm.fmtIsDirection(1),
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(1, 1)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "右脸",
                            src:
                              _vm.faceDirection == 1
                                ? _vm.right_face_active
                                : _vm.right_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("右脸"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: { active: _vm.isShowDataPop },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(2)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "数据",
                            src: _vm.isShowDataPop
                              ? _vm.right_face_active
                              : _vm.right_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("数据"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(3)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: { alt: "对比", src: _vm.contrast_icon },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("对比"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.isShowPageData && _vm.isShowDataPop
        ? _c(
            "div",
            { staticClass: "page-left-top-fixed-data-show-part-wrap-box" },
            [
              _c(
                "div",
                {
                  staticClass: "w-1 left-top-fixed-data-show-content-part-box",
                },
                [
                  _vm.skinTestBigType == "原图"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "卟啉数量:" +
                                  _vm._s(_vm.faceCurrentData.acneNum || 0)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "卟啉程度:" +
                                  _vm._s(_vm.faceCurrentData.acneScore || 0)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "卟啉占比:" +
                                  _vm._s(_vm.faceCurrentData.acneRatio || 0)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "红区数量:" +
                                  _vm._s(_vm.faceCurrentData.redspotNum || 0)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "红区程度:" +
                                  _vm._s(_vm.faceCurrentData.redspotScore || 0)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "红区占比:" +
                                  _vm._s(_vm.faceCurrentData.redspotRatio || 0)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.skinTestBigType == "表素"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "表素数量:" +
                                  _vm._s(
                                    _vm.faceCurrentData.surfacespotNum || 0
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "表素得分:" +
                                  _vm._s(
                                    _vm.faceCurrentData.surfacespotScore || 0
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "表素占比:" +
                                  _vm._s(
                                    _vm.faceCurrentData.surfacespotRatio || 0
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "表素面积:" +
                                  _vm._s(
                                    _vm.faceCurrentData.surfacespotArea || 0
                                  )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.skinTestBigType == "棕斑"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "棕色斑数量:" +
                                  _vm._s(_vm.faceCurrentData.brownspotNum || 0)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "棕色斑得分:" +
                                  _vm._s(
                                    _vm.faceCurrentData.brownspotScore || 0
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "棕色斑占比:" +
                                  _vm._s(
                                    _vm.faceCurrentData.brownspotRatio || 0
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "棕色斑面积:" +
                                  _vm._s(_vm.faceCurrentData.brownspotArea || 0)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.skinTestBigType == "深斑"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "深斑数量:" +
                                  _vm._s(
                                    _vm.faceCurrentData.uvdeepspotNum || 40
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "深斑得分:" +
                                  _vm._s(
                                    _vm.faceCurrentData.uvdeepspotScore || 90
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "深斑占比:" +
                                  _vm._s(
                                    _vm.faceCurrentData.uvdeepspotRatio || 0.5
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "深斑面积:" +
                                  _vm._s(
                                    _vm.faceCurrentData.uvdeepspotArea || 0.5
                                  )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.skinTestBigType == "红区"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "红区数量:" +
                                  _vm._s(_vm.faceCurrentData.redspotNum || 20)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "红区得分:" +
                                  _vm._s(_vm.faceCurrentData.redspotScore || 30)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "红区占比:" +
                                  _vm._s(
                                    _vm.faceCurrentData.redspotRatio || 0.5
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "红区面积:" +
                                  _vm._s(_vm.faceCurrentData.redspotArea || 0.5)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.skinTestBigType == "毛孔"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "毛孔得分:" +
                                  _vm._s(_vm.faceCurrentData.poreNum || 30)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "毛孔数量:" +
                                  _vm._s(_vm.faceCurrentData.poreScore || 20)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "毛孔占比:" +
                                  _vm._s(_vm.faceCurrentData.poreRatio || 0.5)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "毛孔面积:" +
                                  _vm._s(_vm.faceCurrentData.poreArea || 0.5)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.skinTestBigType == "粉刺"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "卟啉数量:" +
                                  _vm._s(_vm.faceCurrentData.acneNum || 20)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "卟啉得分:" +
                                  _vm._s(_vm.faceCurrentData.acneScore || 30)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "卟啉占比:" +
                                  _vm._s(_vm.faceCurrentData.acneRatio || 0.5)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "卟啉面积:" +
                                  _vm._s(_vm.faceCurrentData.acneArea || 0.5)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.skinTestBigType == "纹理"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 left-top-data-content-box fr-b-s warp",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "纹理数量:" +
                                  _vm._s(_vm.faceCurrentData.textureNum || 20)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "纹理得分:" +
                                  _vm._s(_vm.faceCurrentData.textureScore || 30)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "纹理占比:" +
                                  _vm._s(
                                    _vm.faceCurrentData.textureRatio || 0.5
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "left-top-data-item-box fr-s-c" },
                            [
                              _vm._v(
                                "纹理面积:" +
                                  _vm._s(_vm.faceCurrentData.textureArea || 0.5)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-1 left-top-fixed-data-bottom-arrow-part-box fr-c",
                },
                [
                  _c("img", {
                    staticClass: "image-fixed-data-arrow",
                    attrs: { src: _vm.down_arrow_icon },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("changeTimedDialog", {
        attrs: { is_popup: _vm.isPopup },
        on: {
          "update:is_popup": function ($event) {
            _vm.isPopup = $event
          },
          changDatePopConfirmInfo: _vm.clickChangDatePopConfirmBtn,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }