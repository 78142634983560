<template>
  <div class="w-1 change-time-dialog-part-box">
    <div class="popUp page-skin-test-data-contrast-chang-date-popup-part-box fl-c" @touchmove.stop.prevent="onUpFail" v-if="is_popup">
      <div class="skin-test-data-contrast-chang-date-popup-content-bg-part-box fl-b-b" @click.stop="onUpFail">
        <div class="w-1 skin-test-chang-date-popup-title-center-part-box fl-s-c">
          <div class="w-1 skin-test-chang-date-title-box fr-c">
            <span class="color-32 b">请选择</span>
          </div>
          <div class="w-1 skin-test-chang-date-center-part-box fr-b-s">
            <!-- 开始时间 -->
            <div class="chang-date-item-box fl-s-c">
              <div class="w-1 date-item-not-select-picker-part-box">
                <div class="w-1 date-item-not-select-box fr-c" v-if="!timeSelectData.startResultRecordId" @click.stop="clickChangeStartEndDateBtn(1)">
                  <img class="iamges-dete" :src="contrast_pop_date" />
                  <span class="color-32">开始日期</span>
                </div>
                <div class="w-1 date-item-selected-box fl-s-c" v-if="timeSelectData.startResultRecordId">
                  <div class="w-1 fr-c date-selected-time" @click.stop="clickChangeStartEndDateBtn(1)">
                    <span class="color-main">{{$util.formatTime(timeSelectData.startReportTime,'yyyy-mm-dd hh:MM')}}</span>
                  </div>
                  <div class="w-1 fr-c date-selected-image-box">
                    <img class="iamges-origin" :src="timeSelectData.startImgDaylight" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 结束时间 -->
            <div class="chang-date-item-box fl-s-c">
              <div class="w-1 date-item-not-select-box fr-c" v-if="!timeSelectData.endResultRecordId" @click.stop="clickChangeStartEndDateBtn(2)">
                <img class="iamges-dete" :src="contrast_pop_date" />
                <span class="color-32">结束时间</span>
              </div>
              <div class="w-1 date-item-selected-box fl-s-c" v-if="timeSelectData.endResultRecordId">
                <div class="w-1 fr-c date-selected-time" @click.stop="clickChangeStartEndDateBtn(2)">
                  <span class="color-main">{{$util.formatTime(timeSelectData.endReportTime,'yyyy-mm-dd hh:MM')}}</span>
                </div>
                <div class="w-1 fr-c date-selected-image-box">
                  <img class="iamges-origin" :src="timeSelectData.endImgDaylight" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1 skin-test-chang-bottom-btn-part-box fr-e-c">
          <div class="data-chang-btn-box fr-c cancel-btn" @click.stop="updateVisible(false)">
            <span>取消</span>
          </div>
          <div class="data-chang-btn-box fr-c confirm-btn" @click.stop="clickChangDatePopConfirmBtn">
            <span>确定</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 选择时间 -->
    <van-popup v-model="isShowPicker" round position="bottom">
      <van-picker show-toolbar :columns="fmtResultTimeList" value-key="recordTime" :default-index="fmtResultTimeIndex" @cancel="isShowPicker = false" @confirm="onClickConfirmBtn" />
    </van-popup>
  </div>

</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import reportManager from '@/request/reportManager.js'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    is_popup: {
      type: Boolean,
      default: false
    },
    time_data: {
      type: Object,
      default: () => {
        return {
          startTimeIndex: 0,
          startResultRecordId: 0, //开始入店测肤结果id
          startReportTime: 0, //开始入店时间
          startImgDaylight: '', //开始入店-原图
          endTimeIndex: 0,
          endResultRecordId: 0, //结束入店测肤结果id
          endReportTime: '', //结束入店时间
          endImgDaylight: '', //结束入店-原图
        }
      }
    },
  },
  data () {
    // 这里存放数据
    return {
      contrast_pop_date: require('@/assets/image/testReport/report_contrast_pop_date_icon.png'), //对比弹窗日期-图标
      originalTimeList: [], //时间原始数据
      timeSelectData: this.time_data, //选择时间
      selectType: 0,  //选择时间类型 1:开始时间 2：结束时间
      isShowPicker: false,
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getPageContrastReportTimeData(); //获取检测报告对比时间信息
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    // 选择对比时间弹窗--确认按钮
    clickChangDatePopConfirmBtn () {
      if (!this.timeSelectData.startResultRecordId) return this.$util.toast('请选择开始时间');
      if (!this.timeSelectData.endResultRecordId) return this.$util.toast('请选择结束时间');
      this.$emit('changDatePopConfirmInfo', this.timeSelectData);
      this.$emit('update:is_popup', false);
    },
    // 获取检测报告对比时间信息
    async getPageContrastReportTimeData () {
      try {
        let { code, data, message } = await reportManager.getRecordTimeData({ custId: this.$store.getters.custId })
        if (code == 200 && data) {
          this.originalTimeList = data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 点击选择开始、结束时间按钮
    clickChangeStartEndDateBtn (change_type) {
      this.selectType = change_type
      this.isShowPicker = true
    },
    // 选择时间弹窗-确认
    onClickConfirmBtn (value, index) {
      // console.log('选择时间弹窗-确认', value, index)
      if (this.selectType == 1) {
        this.timeSelectData = {
          ...this.timeSelectData,
          ...{
            startTimeIndex: index,
            startResultRecordId: value.terminalScanningResultRecordId, //开始入店测肤结果id
            startReportTime: new Date(value.recordTime).getTime(), //开始入店时间
            startImgDaylight: value.imgDaylight, //开始入店-原图
          }
        }
      } else if (this.selectType == 2) {
        this.timeSelectData = {
          ...this.timeSelectData,
          ...{
            endTimeIndex: index,
            endResultRecordId: value.terminalScanningResultRecordId, //结束入店测肤结果id
            endReportTime: new Date(value.recordTime).getTime(), //结束入店时间
            endImgDaylight: value.imgDaylight, //结束入店-原图
          }
        }
      }
      this.selectType = 0
      this.isShowPicker = false
    },


    /* 更新弹窗是否显示 */
    updateVisible (value) {
      this.$emit('update:is_popup', value);
    },

    //取消事件冒泡
    onUpFail () { return },
  },
  // 监听属性 类似于data概念
  computed: {
    fmtResultTimeList () {
      if (this.selectType == 1) {
        return this.originalTimeList.filter(item => item.terminalScanningResultRecordId != this.timeSelectData.endResultRecordId); //对比开始时间列表
      } else if (this.selectType == 2) {
        return this.originalTimeList.filter(item => item.terminalScanningResultRecordId != this.timeSelectData.startResultRecordId); //对比结束时间列表
      } else {
        return this.originalTimeList;
      }
    },
    fmtResultTimeIndex () {
      if (this.selectType == 1) {
        return this.timeSelectData.startTimeIndex;
      } else if (this.selectType == 2) {
        return this.timeSelectData.endTimeIndex;
      } else {
        return 0
      }
    },
  },
  // 监控data中的数据变化
  watch: {
    is_popup (pop_val) {
      if (pop_val) {
        this.timeSelectData = this.time_data; //选择时间
        this.getPageContrastReportTimeData(); //获取检测报告对比时间信息
      }
    }
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style lang="scss">
.page-skin-test-data-contrast-chang-date-popup-part-box {
  background: rgba(0, 0, 0, 0.5);
  z-index: 99991;
  .skin-test-data-contrast-chang-date-popup-content-bg-part-box {
    width: 700px;
    height: 808px;
    background: #ffffff;
    border-radius: 32px;
    padding: 40px 28px;
    box-sizing: border-box;
    .skin-test-chang-date-title-box {
      margin-bottom: 28px;
      font-size: 40px;
    }
    .chang-date-item-box {
      width: 316px;
      min-height: 92px;
      border-radius: 16px;
      border: 2px solid #e5e5e5;
      padding: 24px 16px;
      box-sizing: border-box;
      &.active {
        height: 468px;
        border-color: #4a90e2;
      }
      .date-item-not-select-box {
        .iamges-dete {
          width: 44px;
          height: 44px;
          margin-right: 16px;
        }
        span {
          font-size: 32px;
        }
      }
      .date-item-selected-box {
        .date-selected-time {
          span {
            font-size: 32px;
          }
        }
        .date-selected-image-box {
          margin-top: 24px;
          .iamges-origin {
            width: 276px;
            height: 343px;
            border-radius: 16px;
          }
        }
      }
    }

    .skin-test-chang-bottom-btn-part-box {
      padding: 40px 0px;
      box-sizing: border-box;
      .data-chang-btn-box {
        width: 260px;
        height: 76px;
        border-radius: 38px;
        cursor: pointer;
        margin-left: 24px;
        span {
          font-size: 32px;
        }
        &.cancel-btn {
          border: 2px solid #e5e5e5;
          color: #646464;
        }

        &.confirm-btn {
          background: #4a90e2;
          color: #fafafa;
          font-weight: bold;
        }
      }
    }
  }
}

.van-overlay {
  z-index: 99991 !important;
}
.van-popup {
  z-index: 99992 !important;
}
</style>
