var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "van-tabs",
        {
          staticClass: "custom-tabs-box",
          attrs: { type: "card" },
          on: { click: _vm.clickChangeTabs },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        _vm._l(_vm.tabList, function (item, index) {
          return _c(
            "van-tab",
            { key: index, attrs: { title: item.lable, name: item.value } },
            [
              _c(
                "div",
                { staticClass: "w-1 page-data-content-part-box" },
                [
                  _vm._l(_vm.trackTaskList, function (item, index) {
                    return _c(
                      "el-card",
                      {
                        key: index,
                        staticClass: "track-task-item-card",
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "el-descriptions",
                          {
                            staticClass: "track-task-item-descriptions",
                            attrs: { column: 2, border: "", size: "small" },
                          },
                          [
                            _c("el-descriptions-item", [
                              _vm._v(
                                "客户分类：" +
                                  _vm._s(
                                    _vm.fmtShowTrackEventTypeText(
                                      item.eventType
                                    )
                                  )
                              ),
                            ]),
                            _c("el-descriptions-item", [
                              _c(
                                "div",
                                {
                                  staticClass: "w-1 fr-c",
                                  class: {
                                    primary: item.taskStatus === "init",
                                    success: item.taskStatus === "finished",
                                    danger: item.taskStatus === "loss",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.fmtShowTrackTaskStatusText(
                                        item.taskStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("el-descriptions-item", [
                              _vm._v("手机号：" + _vm._s(item.custPhone)),
                            ]),
                            _c("el-descriptions-item", [
                              _vm._v("昵称：" + _vm._s(item.custName)),
                            ]),
                            _c(
                              "el-descriptions-item",
                              {
                                attrs: {
                                  span: 2,
                                  contentClassName: "item-task-content",
                                },
                              },
                              [
                                item.taskExample
                                  ? _c(
                                      "div",
                                      { staticClass: "item-task-example-box" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "item-example-btn-box",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickTaskExampleShowHideBtn(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-warning-outline",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("p", { staticClass: "example-text" }, [
                                  _vm._v("来由：" + _vm._s(item.taskContent)),
                                ]),
                                item.isShowExample
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "item-example-tips-text-box",
                                      },
                                      [_vm._v(_vm._s(item.taskExample))]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            (item.taskStatus === "init" &&
                              _vm.tabActive == 0) ||
                            (item.taskStatus !== "init" && item.reviewContent)
                              ? _c(
                                  "el-descriptions-item",
                                  { attrs: { span: 2 } },
                                  [
                                    item.taskStatus === "init" &&
                                    _vm.tabActive == 0
                                      ? _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            autosize: {
                                              minRows: 2,
                                              maxRows: 4,
                                            },
                                            placeholder: "请输入",
                                            resize: "none",
                                          },
                                          model: {
                                            value: item.reviewContent,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "reviewContent",
                                                $$v
                                              )
                                            },
                                            expression: "item.reviewContent",
                                          },
                                        })
                                      : _vm._e(),
                                    item.taskStatus !== "init" &&
                                    item.reviewContent
                                      ? _c("span", [
                                          _vm._v(_vm._s(item.reviewContent)),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        item.taskStatus === "init" && _vm.tabActive == 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bottom-btn-part-box clearfix fr-e-c",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickCustomerDelBtn(item)
                                      },
                                    },
                                  },
                                  [_vm._v("已被客户删除")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickCompleteBtn(item)
                                      },
                                    },
                                  },
                                  [_vm._v("完成")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  !_vm.trackTaskList.length && _vm.showNoData
                    ? _c("el-empty", { attrs: { description: "暂无数据" } })
                    : _vm._e(),
                ],
                2
              ),
              _vm.totalSize && _vm.trackTaskList.length
                ? _c("el-pagination", {
                    staticClass: "t-a-r",
                    attrs: {
                      "pager-count": 5,
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.queryObj.pageSize,
                      total: _vm.totalSize,
                    },
                    on: { "current-change": _vm.changepage },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
      _vm.fmtIsShowModal
        ? _c("div", {
            staticClass: "task-example-modal-part-box",
            on: { click: _vm.clickHideTaskExampleTips },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }