<template>
  <div class='container'>
    <!-- 导航栏 -->
    <!-- <van-nav-bar title="客户跟踪档案" left-arrow @click-left="onClickNavLeftBtn" fixed safe-area-inset-top /> -->
    <!-- tab切换标签 -->
    <van-tabs class="custom-tabs-box" type="card" v-model="tabActive" @click="clickChangeTabs">
      <van-tab :title="item.lable" :name="item.value" v-for="(item, index) in tabList" :key="index">
        <!-- 页面数据内容 -->
        <div class="w-1 page-data-content-part-box">
          <el-card shadow="never" class="track-task-item-card" v-for="(item,index) in trackTaskList" :key="index">
            <el-descriptions class="track-task-item-descriptions" :column="2" border size="small">
              <el-descriptions-item>客户分类：{{fmtShowTrackEventTypeText(item.eventType)}}</el-descriptions-item>
              <el-descriptions-item>
                <div class="w-1 fr-c" :class="{primary:item.taskStatus==='init',success:item.taskStatus==='finished',danger:item.taskStatus==='loss'}">{{fmtShowTrackTaskStatusText(item.taskStatus)}}</div>
              </el-descriptions-item>
              <el-descriptions-item>手机号：{{item.custPhone}}</el-descriptions-item>
              <el-descriptions-item>昵称：{{item.custName}}</el-descriptions-item>
              <el-descriptions-item :span="2" contentClassName="item-task-content">
                <div class="item-task-example-box" v-if="item.taskExample">
                  <div class="item-example-btn-box" @click="clickTaskExampleShowHideBtn(item)"><i class="el-icon-warning-outline"></i></div>
                </div>
                <p class="example-text">来由：{{item.taskContent}}</p>
                <div class="item-example-tips-text-box" v-if="item.isShowExample">{{item.taskExample}}</div>
              </el-descriptions-item>
              <el-descriptions-item :span="2" v-if="(item.taskStatus==='init'&&tabActive==0)||(item.taskStatus!=='init'&&item.reviewContent)">
                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入" resize="none" v-if="item.taskStatus==='init'&&tabActive==0" v-model="item.reviewContent"></el-input>
                <span v-if="item.taskStatus!=='init'&&item.reviewContent">{{ item.reviewContent }}</span>
              </el-descriptions-item>
            </el-descriptions>
            <div class="bottom-btn-part-box clearfix fr-e-c" v-if="item.taskStatus==='init'&&tabActive==0">
              <el-button type="primary" size="small" plain @click="clickCustomerDelBtn(item)">已被客户删除</el-button>
              <el-button type="primary" size="small" @click="clickCompleteBtn(item)">完成</el-button>
            </div>

          </el-card>

          <el-empty description="暂无数据" v-if="!trackTaskList.length&&showNoData"></el-empty>
        </div>
        <el-pagination class="t-a-r" :pager-count="5" background layout="prev, pager, next" :page-size="queryObj.pageSize" :total="totalSize" @current-change="changepage" v-if="totalSize&&trackTaskList.length"></el-pagination>
      </van-tab>
    </van-tabs>

    <div class="task-example-modal-part-box" @click="clickHideTaskExampleTips" v-if="fmtIsShowModal"></div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from "@/request/homeManager.js";
// import { set_config } from '@/tools/sdk-set-config.js'
export default {
  // mixins: [set_config],
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      tabList: [{ value: 0, lable: '今日' }, { value: 1, lable: '昨日' }, { value: 2, lable: '历史' }],
      trackEventTypeList: [],  //任务分类（事件分类）列表
      trackTaskStatusList: [],   //任务状态列表
      tabActive: 0,  //tab切换标签
      queryObj: {
        currentPage: 1, //当前分页
        pageSize: 10, //分页大小
        taskTimeFrom: '',//任务时间，开始值
        taskTimeTo: '', //任务时间，结束时间
      },
      trackTaskList: [],  //跟踪任务列表
      totalSize: 0,
      showNoData: false,
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created () {
    await this.getHandleTabsDateTimeData();  //处理tab选中时间数据
    this.getTrackTaskStatusData();  //任务状态列表数据
    this.getTrackEventTypeData();  //获取任务分类（事件分类）列表
    this.getInitRefreshPageInfo();  //刷新页面数据
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    // 刷新页面数据
    async getInitRefreshPageInfo () {
      this.queryObj.currentPage = 1
      this.getPageTrackTaskData();  //获取跟踪任务数据
    },
    // 获取跟踪任务数据
    async getPageTrackTaskData () {
      this.$toast.loading({ message: '加载中...', forbidClick: true, duration: 0 });
      try {
        let { code, data, message } = await homeManager.getTrackTaskSearchInfo(this.queryObj)
        if (code == 200 && data) {
          data.dataList.forEach(item => item.isShowExample = false);
          this.trackTaskList = data.dataList
          this.totalSize = data.totalSize
          this.showNoData = (!data.dataList.length) && this.queryObj.currentPage == 1
        } else {
          this.trackTaskList = this.queryObj.currentPage == 1 ? [] : this.trackTaskList
          this.totalSize = 0
          this.showNoData = this.queryObj.currentPage == 1
        }
      } catch (error) {
        console.log('获取跟踪任务数据', error)
        this.trackTaskList = this.queryObj.currentPage == 1 ? [] : this.trackTaskList
        this.totalSize = 0
        this.showNoData = this.queryObj.currentPage == 1
      } finally {
        this.$toast.clear();
      }
    },
    // 切换分页页数
    changepage (val) {
      this.queryObj.currentPage = val
      this.getPageTrackTaskData();  //获取跟踪任务数据
    },
    // 点击是否显示气泡弹窗
    clickTaskExampleShowHideBtn (item) {
      item.isShowExample = !item.isShowExample
    },
    // 点击隐藏全部气泡
    clickHideTaskExampleTips () {
      this.trackTaskList.forEach(item => item.isShowExample = false);
    },

    // 点击已被客户删除
    async clickCustomerDelBtn (item) {
      try {
        let { code, data, message } = await homeManager.postTrackTaskLossInfo({
          taskId: item.taskId,  //任务id
          reviewContent: item.reviewContent  //回访内容，非必填
        })
        if (code == 200) {
          this.$toast.success(message);
          this.getInitRefreshPageInfo();  //刷新页面数据
        }
      } catch (error) {
        console.log('点击已被客户删除', error)
      }
    },
    // 点击完成按钮
    async clickCompleteBtn (item) {
      if (!item.reviewContent) return this.$toast.fail('请输入回访内容');
      try {
        let { code, data, message } = await homeManager.postTrackTaskFinishedInfo({
          taskId: item.taskId,  //任务id
          reviewContent: item.reviewContent  //回访内容，必填
        })
        if (code == 200) {
          this.$toast.success(message);
          this.getInitRefreshPageInfo();  //刷新页面数据
        }
      } catch (error) {
        console.log('点击已被客户删除', error)
      }
    },



    // 点击切换tab标签
    async clickChangeTabs () {
      console.log('点击切换tab标签', this.tabActive)
      await this.getHandleTabsDateTimeData();  //处理tab选中时间数据
      this.getInitRefreshPageInfo();  //刷新页面数据
    },
    // 处理tab选中时间数据
    getHandleTabsDateTimeData () {
      const today_val = new Date(new Date().setDate(new Date().getDate() - this.tabActive));
      let time_from = this.$util.formatTime(today_val.setHours(0, 0, 0, 0), 'yyyy-mm-dd hh:MM:ss');
      let time_to = this.$util.formatTime(today_val.setHours(23, 59, 59, 999), 'yyyy-mm-dd hh:MM:ss');
      this.queryObj.taskTimeFrom = this.tabActive == 2 ? '' : time_from;
      this.queryObj.taskTimeTo = time_to;
    },

    // 任务状态列表数据
    async getTrackTaskStatusData () {
      try {
        let { code, data, message } = await homeManager.getTrackTaskStatusInfo()
        if (code == 200 && data) {
          this.trackTaskStatusList = data
        } else {
          this.trackTaskStatusList = []
        }
      } catch (error) {
        console.error('任务状态列表数据', error)
        this.trackTaskStatusList = []
      }
    },
    // 获取任务分类（事件分类）列表
    async getTrackEventTypeData () {
      try {
        let { code, data, message } = await homeManager.getTrackEventTypeInfo()
        if (code == 200 && data) {
          this.trackEventTypeList = data
        } else {
          this.trackEventTypeList = []
        }
      } catch (error) {
        console.error('获取任务分类（事件分类）列表', error)
        this.trackEventTypeList = []
      }
    },
    // 点击导航栏返回按钮
    onClickNavLeftBtn () {
      // console.log('点击导航栏返回按钮')
      this.$router.go(-1);
    },

  },
  // 监听属性 类似于data概念
  computed: {
    fmtShowTrackEventTypeText () {   //处理展示任务分类（事件分类）列表
      return (trackEventType_val) => {
        let trackEventType_obj = this.trackEventTypeList.find(item => item.dictItemCode == trackEventType_val) || null;
        return trackEventType_obj ? trackEventType_obj.dictItemName : '-';
      }
    },
    fmtShowTrackTaskStatusText () {   //处理展示任务状态
      return (taskStatus_val) => {
        let taskStatus_obj = this.trackTaskStatusList.find(item => item.dictItemCode == taskStatus_val) || null;
        return taskStatus_obj ? taskStatus_obj.dictItemName : '-';
      }
    },
    fmtIsShowModal () {
      return this.trackTaskList.some(item => item.isShowExample);
    }
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.container {
  min-height: 100vh;
  background: #f7f7f7;
  // padding-top: calc(92px + env(safe-area-inset-top));
  // box-sizing: border-box;
}
/* tab切换标签 */
.custom-tabs-box {
  padding: 20px 0px 30px;
  ::v-deep .van-tabs__wrap {
    width: 100%;
    background: #ffffff;
    padding: 20px 0px;
    position: fixed;
    top: 70px;  //calc(92px + env(safe-area-inset-top))
    left: 0px;
    z-index: 99;
    .van-tabs__nav--card {
      border-color: #409eff;
      .van-tab {
        border-color: #409eff;
        color: #409eff;
      }
      .van-tab--active {
        background: #409eff;
        color: #ffffff;
      }
    }
  }
  ::v-deep .van-tabs__content {
    padding: 100px 0px 0px;
    box-sizing: border-box;
  }
}
/* 页面数据内容 */
.page-data-content-part-box {
  ::v-deep .track-task-item-card {
    border: none;
    overflow: visible;
    margin-bottom: 30px;
    .el-card__body {
      padding: 30px 30px;
    }
  }
  ::v-deep .track-task-item-descriptions {
    .el-descriptions-item__label {
      padding: 0px;
      display: none;
    }
    .item-task-content {
      padding: 30px 20px;
      position: relative;
      .item-task-example-box {
        position: absolute;
        top: 10px;
        left: 20px;
        .item-example-btn-box {
          padding: 17px 0px 0px;
          color: #f56c6c;
          font-size: 30px;
        }
      }
      .example-text {
        text-indent: 40px;
      }
      .item-example-tips-text-box {
        max-width: 400px;
        background: #303133;
        padding: 15px 15px;
        border-radius: 8px;
        position: absolute;
        top: 76px;
        left: 20px;
        font-size: 26px;
        color: #ffffff;
        z-index: 9991;
        &::before {
          content: '';
          width: 0;
          height: 0;
          border-color: transparent;
          border-style: solid;
          border-width: 8px;
          border-top-width: 0;
          border-bottom-color: #303133;
          -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
          position: absolute;
          top: 0px;
          left: 15px;
        }
      }
    }
  }

  .bottom-btn-part-box {
    padding: 30px 0px 0px;
    box-sizing: border-box;
  }
}

.danger {
  color: #f56c6c;
}
.success {
  color: #67c23a;
}
.warning {
  color: #e6a23c;
}
.primary {
  color: #409eff;
}
.task-example-modal-part-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: calc(192px + env(safe-area-inset-top));
  left: 0px;
  z-index: 999;
}
</style>
