var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container contrast-report-box fl-s-c" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "检测图",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
        },
        on: { "click-left": _vm.onClickNavLeftBtn },
      }),
      _vm.isShowPageData
        ? _c(
            "div",
            {
              staticClass: "w-1 flex-1 page-content-part-wrap-box fl-b-b",
              class: { "page-images-ff": _vm.fmtIsShowImgWhiteBg },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-1 flex-1 fl-c relative skin-test-results-images-change-slider-dialog-part-wrap-box",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1 flex-1 fl-s-c skin-test-results-images-dialog-data-btn-show-content-part-box",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-1 fl-c contrast-skin-test-img-part-box contrast-start-time-skin-test-results-images-part-box relative",
                        },
                        [
                          _c(
                            "div",
                            {
                              ref: "areaBoxRef",
                              staticClass:
                                "w-1 fl-c skin-test-results-images-movable-area-part-box",
                            },
                            [
                              _c(
                                "div",
                                {
                                  ref: "dragBoxRef",
                                  staticClass:
                                    "fl-c skin-test-results-images-movable-div-part-box",
                                  style: {
                                    transform: `scale(${_vm.scaleOffsetVal.scaleValue}) translate(${_vm.scaleOffsetVal.offsetValueX}px, ${_vm.scaleOffsetVal.offsetValueY}px)`,
                                  },
                                  on: {
                                    wheel: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleMouseWheelScaleScroll.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    mousedown: _vm.handleMouseDownMove,
                                    touchmove: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleTouchMoveCallback.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    touchstart: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleTouchStartCallback.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    touchend: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleTouchEndCallback.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      ref: "imgRef",
                                      staticClass:
                                        "relative skin-test-results-images-box",
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "iamges-style images-bottom-layer",
                                        attrs: {
                                          alt: "底层图片",
                                          src: _vm.startSkinImgCurrentObj[
                                            _vm.skinTestType == 0 ||
                                            _vm.skinTestType == 1
                                              ? "skinBaseUrl"
                                              : "skinBaseTestUrl"
                                          ],
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1 relative overlay-layer-style-part-box",
                                        },
                                        [
                                          _vm.fmtIsShowChangeBswl
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overlay-layer-box base-map-images-box",
                                                  style: {
                                                    width: `${_vm.imgSlideX}%`,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "iamges-style images-base-map",
                                                    attrs: {
                                                      alt: "底图",
                                                      src: _vm
                                                        .startSkinImgCurrentObj[
                                                        _vm.skinTestType == 1
                                                          ? "skinBaseUrl"
                                                          : "skinBaseTestUrl"
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.skinTestBigType != "原图" &&
                                          _vm.skinTestType != 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1 h-1 overlay-layer-box stack-iamges-box",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "iamges-style iamges-stack",
                                                    attrs: {
                                                      alt: "叠加图片",
                                                      src: _vm
                                                        .startSkinImgCurrentObj[
                                                        _vm.skinTestType == 1
                                                          ? "skinDestUrl"
                                                          : "skinDestTestUrl"
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm.isShowDataPop
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "contrast-skin-test-time-part-box fr-s-c",
                                },
                                [
                                  _c("span", { staticClass: "m-r-15" }, [
                                    _vm._v("上"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.formatTime(
                                          _vm.startResultReportTime,
                                          "yyyy-mm-dd hh:MM:ss"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-1 fl-c contrast-skin-test-img-part-box contrast-end-time-skin-test-results-images-part-box relative",
                        },
                        [
                          _c(
                            "div",
                            {
                              ref: "areaBoxRef",
                              staticClass:
                                "w-1 fl-c skin-test-results-images-movable-area-part-box",
                            },
                            [
                              _c(
                                "div",
                                {
                                  ref: "dragBoxRef",
                                  staticClass:
                                    "fl-c skin-test-results-images-movable-div-part-box",
                                  style: {
                                    transform: `scale(${_vm.scaleOffsetVal.scaleValue}) translate(${_vm.scaleOffsetVal.offsetValueX}px, ${_vm.scaleOffsetVal.offsetValueY}px)`,
                                  },
                                  on: {
                                    wheel: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleMouseWheelScaleScroll.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    mousedown: _vm.handleMouseDownMove,
                                    touchmove: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleTouchMoveCallback.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    touchstart: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleTouchStartCallback.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    touchend: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleTouchEndCallback.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      ref: "imgRef",
                                      staticClass:
                                        "relative skin-test-results-images-box",
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "iamges-style images-bottom-layer",
                                        attrs: {
                                          alt: "底层图片",
                                          src: _vm.endSkinImgCurrentObj[
                                            _vm.skinTestType == 0 ||
                                            _vm.skinTestType == 1
                                              ? "skinBaseUrl"
                                              : "skinBaseTestUrl"
                                          ],
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1 relative overlay-layer-style-part-box",
                                        },
                                        [
                                          _vm.fmtIsShowChangeBswl
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overlay-layer-box base-map-images-box",
                                                  style: {
                                                    width: `${_vm.imgSlideX}%`,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "iamges-style images-base-map",
                                                    attrs: {
                                                      alt: "底图",
                                                      src: _vm
                                                        .endSkinImgCurrentObj[
                                                        _vm.skinTestType == 1
                                                          ? "skinBaseUrl"
                                                          : "skinBaseTestUrl"
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.skinTestBigType != "原图" &&
                                          _vm.skinTestType != 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1 h-1 overlay-layer-box stack-iamges-box",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "iamges-style iamges-stack",
                                                    attrs: {
                                                      alt: "叠加图片",
                                                      src: _vm
                                                        .endSkinImgCurrentObj[
                                                        _vm.skinTestType == 1
                                                          ? "skinDestUrl"
                                                          : "skinDestTestUrl"
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm.isShowDataPop
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "contrast-skin-test-time-part-box fr-s-c",
                                },
                                [
                                  _c("span", { staticClass: "m-r-15" }, [
                                    _vm._v("下"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.formatTime(
                                          _vm.endResultReportTime,
                                          "yyyy-mm-dd hh:MM:ss"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1 skin-test-results-type-change-slider-part-wrap-box fr-c-e",
                    },
                    [
                      _vm.fmtIsShowChangeSlider
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-1 h-1 skin-test-results-type-change-slider-content-part-box fl-c",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1 fr-c skin-test-results-type-change-part-box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "results-type-change-box fr-b-c",
                                    },
                                    [
                                      _vm.fmtIsShowChangeOther
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "change-type-item fr-c",
                                              class: {
                                                active: _vm.skinTestType == 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.clickResultChangeTypeItemBtn(
                                                    0
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("原图")]
                                          )
                                        : _vm._e(),
                                      _vm.fmtIsShowChangeBswl
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "change-type-item fr-c",
                                              class: {
                                                active: _vm.skinTestType == 2,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.clickResultChangeTypeItemBtn(
                                                    2
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("预测")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "change-type-item fr-c",
                                          class: {
                                            active: _vm.skinTestType == 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickResultChangeTypeItemBtn(
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("症状")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.fmtIsShowChangeBswl
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1 skin-test-results-slider-part-box fr-c",
                                    },
                                    [
                                      _c("van-slider", {
                                        attrs: {
                                          min: 0,
                                          max: 100,
                                          "inactive-color":
                                            "rgba(255, 255, 255, 0.4)",
                                          "active-color": "#ffffff",
                                        },
                                        model: {
                                          value: _vm.imgSlideX,
                                          callback: function ($$v) {
                                            _vm.imgSlideX = $$v
                                          },
                                          expression: "imgSlideX",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-1 page-bootom-skin-test-result-imgTab-part-wrap-box fr-c",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "h-1 bootom-skin-test-result-imgTab-scroll",
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "bootom-skin-test-result-imgTab-content-part-box fr-s-c",
                        },
                        _vm._l(_vm.startSkinImgTabList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "skin-test-imgTab-item-box fl-c",
                              class: {
                                active: _vm.skinTestBigType == item.skinText,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgTab-item-content-box fl-c",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clickSkinTestImgTabBtnItem(
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "imgTab-item-small-img-box fr-c relative",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "iamges-small-base",
                                        attrs: {
                                          alt: "底图",
                                          src: item.skinBaseUrl,
                                        },
                                      }),
                                      item.skinText != "原图"
                                        ? _c("img", {
                                            staticClass: "iamges-small-dest",
                                            attrs: {
                                              alt: "叠加图",
                                              src: item.skinDestUrl,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("p", { staticClass: "imgTab-item-text" }, [
                                    _vm._v(_vm._s(item.skinText)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.isShowPageData
        ? _c(
            "div",
            {
              staticClass: "page-right-top-fixed-btn-show-part-wrap-box",
              class: { "right-top-fixed-ff": _vm.fmtIsShowImgWhiteBg },
            },
            [
              _c(
                "ul",
                {
                  staticClass:
                    "right-top-fixed-btn-show-content-part-box fl-s-c",
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: {
                        active: _vm.faceDirection == -1,
                        disable: !_vm.fmtIsDirection(-1),
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(1, -1)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "左脸",
                            src:
                              _vm.faceDirection == -1
                                ? _vm.left_face_active
                                : _vm.left_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("左脸"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: {
                        active: _vm.faceDirection == 0,
                        disable: !_vm.fmtIsDirection(0),
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(1, 0)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "正脸",
                            src:
                              _vm.faceDirection == 0
                                ? _vm.main_face_active
                                : _vm.main_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("正脸"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: {
                        active: _vm.faceDirection == 1,
                        disable: !_vm.fmtIsDirection(1),
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(1, 1)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "右脸",
                            src:
                              _vm.faceDirection == 1
                                ? _vm.right_face_active
                                : _vm.right_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("右脸"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "right-top-btn-item-box fl-c",
                      class: { active: _vm.isShowDataPop },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickRightTopBtnItem(2)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "btn-item-iocn-box fr-c" }, [
                        _c("img", {
                          staticClass: "images-btn-icon",
                          attrs: {
                            alt: "数据",
                            src: _vm.isShowDataPop
                              ? _vm.right_face_active
                              : _vm.right_face_default,
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "btn-item-text" }, [
                        _vm._v("数据"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.isShowPageData && _vm.isShowDataPop
        ? _c(
            "div",
            {
              staticClass:
                "page-left-top-fixed-contrast-data-show-part-wrap-box",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-1 left-top-fixed-start-contrast-data-part-box",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "w-1 table-left-top-fixed-contrast-data-box",
                    },
                    [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "w-1 toby-contrast-data-box fl-s-c" },
                        [
                          _c("div", { staticClass: "w-1 scroll-toby-box" }, [
                            _c(
                              "ul",
                              { staticClass: "w-1 contrast-data-content-box" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-1 scroll-toby-box" },
                                  [
                                    _vm.skinTestBigType == "原图"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(1),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .acneNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .acneNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .acneNum,
                                                      _vm.endFaceCurrentData
                                                        .acneNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .acneNum,
                                                          _vm.endFaceCurrentData
                                                            .acneNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(2),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .acneScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .acneScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .acneScore,
                                                      _vm.endFaceCurrentData
                                                        .acneScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .acneScore,
                                                          _vm.endFaceCurrentData
                                                            .acneScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(3),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .acneRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .acneRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .acneRatio,
                                                      _vm.endFaceCurrentData
                                                        .acneRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .acneRatio,
                                                          _vm.endFaceCurrentData
                                                            .acneRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(4),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .redspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .redspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .redspotNum,
                                                      _vm.endFaceCurrentData
                                                        .redspotNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .redspotNum,
                                                          _vm.endFaceCurrentData
                                                            .redspotNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(5),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .redspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .redspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .redspotScore,
                                                      _vm.endFaceCurrentData
                                                        .redspotScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .redspotScore,
                                                          _vm.endFaceCurrentData
                                                            .redspotScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(6),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .redspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .redspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .redspotRatio,
                                                      _vm.endFaceCurrentData
                                                        .redspotRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .redspotRatio,
                                                          _vm.endFaceCurrentData
                                                            .redspotRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.skinTestBigType == "表素"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(7),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .surfacespotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .surfacespotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .surfacespotNum,
                                                      _vm.endFaceCurrentData
                                                        .surfacespotNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .surfacespotNum,
                                                          _vm.endFaceCurrentData
                                                            .surfacespotNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(8),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .surfacespotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .surfacespotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .surfacespotScore,
                                                      _vm.endFaceCurrentData
                                                        .surfacespotScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .surfacespotScore,
                                                          _vm.endFaceCurrentData
                                                            .surfacespotScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(9),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .surfacespotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .surfacespotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .surfacespotRatio,
                                                      _vm.endFaceCurrentData
                                                        .surfacespotRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .surfacespotRatio,
                                                          _vm.endFaceCurrentData
                                                            .surfacespotRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(10),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .surfacespotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .surfacespotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .surfacespotArea,
                                                      _vm.endFaceCurrentData
                                                        .surfacespotArea
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .surfacespotArea,
                                                          _vm.endFaceCurrentData
                                                            .surfacespotArea
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.skinTestBigType == "棕斑"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(11),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .brownspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .brownspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .brownspotNum,
                                                      _vm.endFaceCurrentData
                                                        .brownspotNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .brownspotNum,
                                                          _vm.endFaceCurrentData
                                                            .brownspotNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(12),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .brownspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .brownspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .brownspotScore,
                                                      _vm.endFaceCurrentData
                                                        .brownspotScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .brownspotScore,
                                                          _vm.endFaceCurrentData
                                                            .brownspotScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(13),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .brownspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .brownspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .brownspotRatio,
                                                      _vm.endFaceCurrentData
                                                        .brownspotRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .brownspotRatio,
                                                          _vm.endFaceCurrentData
                                                            .brownspotRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(14),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .brownspotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .brownspotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .brownspotArea,
                                                      _vm.endFaceCurrentData
                                                        .brownspotArea
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .brownspotArea,
                                                          _vm.endFaceCurrentData
                                                            .brownspotArea
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.skinTestBigType == "深斑"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(15),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .uvdeepspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .uvdeepspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .uvdeepspotNum,
                                                      _vm.endFaceCurrentData
                                                        .uvdeepspotNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .uvdeepspotNum,
                                                          _vm.endFaceCurrentData
                                                            .uvdeepspotNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(16),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .uvdeepspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .uvdeepspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .uvdeepspotScore,
                                                      _vm.endFaceCurrentData
                                                        .uvdeepspotScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .uvdeepspotScore,
                                                          _vm.endFaceCurrentData
                                                            .uvdeepspotScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(17),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .uvdeepspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .uvdeepspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .uvdeepspotRatio,
                                                      _vm.endFaceCurrentData
                                                        .uvdeepspotRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .uvdeepspotRatio,
                                                          _vm.endFaceCurrentData
                                                            .uvdeepspotRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(18),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .uvdeepspotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .uvdeepspotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .uvdeepspotArea,
                                                      _vm.endFaceCurrentData
                                                        .uvdeepspotArea
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .uvdeepspotArea,
                                                          _vm.endFaceCurrentData
                                                            .uvdeepspotArea
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.skinTestBigType == "红区"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(19),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .redspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .redspotNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .redspotNum,
                                                      _vm.endFaceCurrentData
                                                        .redspotNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .redspotNum,
                                                          _vm.endFaceCurrentData
                                                            .redspotNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(20),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .redspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .redspotScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .redspotScore,
                                                      _vm.endFaceCurrentData
                                                        .redspotScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .redspotScore,
                                                          _vm.endFaceCurrentData
                                                            .redspotScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(21),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .redspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .redspotRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .redspotRatio,
                                                      _vm.endFaceCurrentData
                                                        .redspotRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .redspotRatio,
                                                          _vm.endFaceCurrentData
                                                            .redspotRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(22),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .redspotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .redspotArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .redspotArea,
                                                      _vm.endFaceCurrentData
                                                        .redspotArea
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .redspotArea,
                                                          _vm.endFaceCurrentData
                                                            .redspotArea
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.skinTestBigType == "毛孔"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(23),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .poreNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .poreNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .poreNum,
                                                      _vm.endFaceCurrentData
                                                        .poreNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .poreNum,
                                                          _vm.endFaceCurrentData
                                                            .poreNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(24),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .poreScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .poreScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .poreScore,
                                                      _vm.endFaceCurrentData
                                                        .poreScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .poreScore,
                                                          _vm.endFaceCurrentData
                                                            .poreScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(25),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .poreRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .poreRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .poreRatio,
                                                      _vm.endFaceCurrentData
                                                        .poreRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .poreRatio,
                                                          _vm.endFaceCurrentData
                                                            .poreRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(26),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .poreArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .poreArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .poreArea,
                                                      _vm.endFaceCurrentData
                                                        .poreArea
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .poreArea,
                                                          _vm.endFaceCurrentData
                                                            .poreArea
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.skinTestBigType == "粉刺"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(27),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .acneNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .acneNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .acneNum,
                                                      _vm.endFaceCurrentData
                                                        .acneNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .acneNum,
                                                          _vm.endFaceCurrentData
                                                            .acneNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(28),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .acneScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .acneScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .acneScore,
                                                      _vm.endFaceCurrentData
                                                        .acneScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .acneScore,
                                                          _vm.endFaceCurrentData
                                                            .acneScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(29),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .acneRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .acneRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .acneRatio,
                                                      _vm.endFaceCurrentData
                                                        .acneRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .acneRatio,
                                                          _vm.endFaceCurrentData
                                                            .acneRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(30),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .acneArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .acneArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .acneArea,
                                                      _vm.endFaceCurrentData
                                                        .acneArea
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .acneArea,
                                                          _vm.endFaceCurrentData
                                                            .acneArea
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.skinTestBigType == "纹理"
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "w-1 contrast-data-content-box",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(31),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .textureNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .textureNum
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .textureNum,
                                                      _vm.endFaceCurrentData
                                                        .textureNum
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .textureNum,
                                                          _vm.endFaceCurrentData
                                                            .textureNum
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(32),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .textureScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .textureScore
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .textureScore,
                                                      _vm.endFaceCurrentData
                                                        .textureScore
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .textureScore,
                                                          _vm.endFaceCurrentData
                                                            .textureScore
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(33),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .textureRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .textureRatio
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .textureRatio,
                                                      _vm.endFaceCurrentData
                                                        .textureRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .textureRatio,
                                                          _vm.endFaceCurrentData
                                                            .textureRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                              },
                                              [
                                                _vm._m(34),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.startFaceCurrentData
                                                          .textureArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-e-c td-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.endFaceCurrentData
                                                          .textureArea
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td-data-item-box fr-c td-4",
                                                  },
                                                  [
                                                    _vm.fmtContrastNum(
                                                      _vm.startFaceCurrentData
                                                        .textureRatio,
                                                      _vm.endFaceCurrentData
                                                        .textureRatio
                                                    ) == 1
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_rise,
                                                          },
                                                        })
                                                      : _vm.fmtContrastNum(
                                                          _vm
                                                            .startFaceCurrentData
                                                            .textureRatio,
                                                          _vm.endFaceCurrentData
                                                            .textureRatio
                                                        ) == 2
                                                      ? _c("img", {
                                                          staticClass:
                                                            "images-contrast",
                                                          attrs: {
                                                            src: _vm.data_contrast_drop,
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v("-"),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "w-1 tr-contrast-data-box th-tr-data-box fr-b-c" },
      [
        _c("div", { staticClass: "th-data-item-box fr-s-c th-1" }, [
          _vm._v("参数"),
        ]),
        _c("div", { staticClass: "th-data-item-box fr-e-c th-2" }, [
          _vm._v("报告1"),
        ]),
        _c("div", { staticClass: "th-data-item-box fr-e-c th-3" }, [
          _vm._v("报告2"),
        ]),
        _c("div", { staticClass: "th-data-item-box fr-e-c th-4" }, [
          _vm._v("变化"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("卟啉数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("卟啉程度")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("卟啉占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("红区数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("红区程度")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("红区占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("表素数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("表素得分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("表素占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("表素面积")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("棕色斑数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("棕色斑得分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("棕色斑占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("棕色斑面积")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("深斑数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("深斑得分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("深斑占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("深斑面积")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("红区数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("红区得分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("红区占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("红区面积")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("毛孔数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("毛孔得分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("毛孔占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("毛孔面积")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("卟啉数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("卟啉得分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("卟啉占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("卟啉面积")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("纹理数量")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("纹理得分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("纹理占比")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "td-data-item-box fr-s-c td-1" }, [
      _c("i", { staticClass: "text-line-1" }, [_vm._v("纹理面积")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }