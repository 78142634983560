var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "测肤报告",
          "left-arrow": "",
          fixed: "",
          placeholder: "",
        },
        on: { "click-left": _vm.close },
      }),
      _c(
        "div",
        { staticClass: "con" },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "", column: 3, border: "" } },
            [
              _c("el-descriptions-item", { attrs: { label: "肤龄:" } }, [
                _vm._v(_vm._s(_vm.dataInfo.age)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "肤色:" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("getStateName")(_vm.dataInfo.color, _vm.colorType)
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "肤质:" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("getStateName")(_vm.dataInfo.skinType, _vm.skinType)
                  )
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.dataList, function (item, index) {
        return _c("div", { key: index, staticClass: "con" }, [
          _c("div", { staticClass: "fr-b-c" }, [
            _c("div", { staticClass: "tit" }, [_vm._v(_vm._s(item.name))]),
            _c(
              "div",
              [
                _c(
                  "el-tag",
                  { staticClass: "tag", attrs: { effect: "dark" } },
                  [_vm._v(_vm._s(item.value) + "分")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "fr-b-c" }, [
            _c("img", {
              staticClass: "imgs",
              attrs: { src: item.img, alt: "" },
            }),
            _c(
              "div",
              { staticClass: "fr-c titles" },
              _vm._l(item.list, function (item2, index2) {
                return _c("div", { key: index2, staticClass: "info" }, [
                  _c("div", [_vm._v(_vm._s(item2.label))]),
                  _c("div", { staticStyle: { "padding-top": "10px" } }, [
                    _vm._v(_vm._s(item2.value)),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }