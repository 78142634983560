<template>
  <div class='container'>
    <!-- 导航栏 -->
    <van-nav-bar title="客户治疗方案" left-arrow @click-left="onClickNavLeftBtn" fixed safe-area-inset-top />
    <!-- 页面数据内容 -->
    <div class="w-1 page-data-content-part-box">
      <el-card shadow="never" class="treatment-plan-item-card" v-for="(storeItem,storendex) in treatmentPlanList" :key="storendex">
        <div slot="header" class="clearfix">
          <h3>{{storeItem.yzStoreName}}</h3>
        </div>
        <div class="w-1 item-card-content-part-box">
          <el-descriptions class="treatment-plan-item-descriptions" :column="2" border size="small" v-for="(item,index) in storeItem.treatmentList" :key="index">
            <el-descriptions-item label="id" :span="2">{{item.treatmentId}}</el-descriptions-item>
            <el-descriptions-item label="项目">{{item.serviceName}}</el-descriptions-item>
            <el-descriptions-item label="总/剩余次数">{{item.totalTimes}}/{{item.leftTimes}}</el-descriptions-item>
            <el-descriptions-item label="下次服务时间">{{item.nextServiceTime||'-'}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{item.nextServiceStatus||'-'}}</el-descriptions-item>
            <el-descriptions-item label="支付方式">{{item.paymentInfo||'-'}}</el-descriptions-item>
            <el-descriptions-item label="开具时间">{{item.createTime||'-'}}</el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>

      <el-empty description="暂无数据" v-if="!treatmentPlanList.length&&showNoData"></el-empty>

    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from "@/request/homeManager.js";
import { set_config } from '@/tools/sdk-set-config.js'
export default {
  mixins: [set_config],
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      treatmentPlanList: [],  //客户治疗方案列表
      showNoData: false,
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    // 刷新页面数据
    async getInitRefreshPageInfo () {
      this.getPageTreatmentPlanData();  //获取页面客户治疗方案数据
    },
    // 获取页面客户治疗方案数据
    async getPageTreatmentPlanData () {
      this.$toast.loading({ message: '加载中...', forbidClick: true, duration: 0 });
      try {
        let { code, data, message } = await homeManager.getTreatmentPlanInfo({ custId: this.$store.getters.custId })  //客户id
        if (code == 200 && data) {
          this.treatmentPlanList = data
        } else {
          this.treatmentPlanList = []
        }
      } catch (error) {
        console.log('获取页面客户治疗方案数据', error)
        this.treatmentPlanList = []
      } finally {
        this.$toast.clear();
        this.showNoData = !this.treatmentPlanList.length
      }
    },




    // 点击导航栏返回按钮
    onClickNavLeftBtn () {
      // console.log('点击导航栏返回按钮')
      this.$router.go(-1);
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.container {
  min-height: 100vh;
  background: #f7f7f7;
  padding-top: calc(92px + env(safe-area-inset-top));
  box-sizing: border-box;
}
/* 页面数据内容 */
.page-data-content-part-box {
  padding: 30px 0px 20px;
  box-sizing: border-box;
  ::v-deep .treatment-plan-item-card {
    border: none;
    margin-bottom: 25px;
    .el-card__header {
      padding: 15px 30px;
      h3 {
        font-size: 34px;
      }
    }
    .el-card__body {
      padding: 20px 12px 15px;
    }

    .treatment-plan-item-descriptions {
      margin-bottom: 15px;
      .el-descriptions-item__cell {
        padding: 20px 12px;
      }
      .el-descriptions-item__label {
        width: 140px;
      }
      .el-descriptions-row {
        .el-descriptions-item__content {
          &:last-child {
            width: 160px;
          }
        }
      }
    }
  }
}
</style>
